<template>
  <div class="home-wrap">
    <Transition name="fade">
      <PopupMenu
        v-if="isExpanded"
        @closeBurgir="closeBurgir"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
      />
    </Transition>
    <HeaderComp @openBurgir="openBurgir" />
    <div class="content-wrap">
      <Sidebar v-if="true" />
      <div
        class="content-component"
        :style="[isBurger || isMobile ? 'margin-left: 0' : '']"
      >
        <RouterView v-slot="{ Component }">
          <Transition name="router-fade" mode="out-in">
            <component :is="Component" />
          </Transition>
        </RouterView>
      </div>
    </div>
    <FooterComp />
  </div>
</template>
<script setup>
import { RouterView } from "vue-router";

let isBurger = inject("isBurger");
let isMobile = inject("isMobile");
let isExpanded = ref(false);

const openBurgir = () => {
  isExpanded.value = true;
};
const closeBurgir = () => {
  isExpanded.value = false;
};
</script>

<style lang="scss" scoped>
.home-wrap {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .content-wrap {
    width: 100%;
    min-height: 100vh;
    padding: 38px 90px 38px 100px;
    display: flex;

    .content-component {
      width: 100%;
      height: 100%;
      margin-left: 60px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .home-wrap {
    .content-wrap {
      // padding: 40px 27px 40px 27px;
       padding: 30px 17px 30px 21px;
      .content-component {
        margin-left: 0;
      }
    }
  }
}
</style>
