<template>
  <div class="ccc-wrapper">
      <!-- if set visibility:hidden or display:none it dose not working -->
      <input type="text" name="name" value="" autocomplete="on" style="height:0px;width: 0px;border:none ">
      <input type="password" name="password" value="" autocomplete="on"  style="height:0px;width: 0px;border:none ">
    <div
      class="confirm-certificate-content"
      v-for="(item, index) in certificates"
      :key="index"
    >
      <div 
      :style="{color: item.error? 'red': ''}"
      class="file-name"
      >{{ item.file.name }}</div>
      <!-- <div class="info-wrap"> -->
        <div class="cert-name">
          <BaseInput
            class="cert-input-name cert-confirm-input"
            :label="$t('app.compnay.certificateName')"
            v-model="item.cert_name"
          ></BaseInput>
        </div>
        <div class="cert-pass">

          <div class="input-wrap">
          <label class="input-label" for="input">
            PAC
          </label>
          <input
            class="custom-input cert-input-pass cert-confirm-input"
            label="PAC"
            :value="item.pac"
            @input="event => item.pac = event.target.value.toUpperCase()"
            maxlength="6"
            autocomplete="off">
          </div>
          <!-- :notification="item.error ? $t(item.error) : '' " -->
        </div>
        <div class="cert-pass">
          <BaseInput
            class="cert-input-pass cert-confirm-input"
            :label="$t('app.compnay.certificatePass')"
            v-model="item.pass"
            :password="true"
          ></BaseInput>
          <!-- :notification="item.error ? $t(item.error) : '' " -->
        </div>
       
      <!-- </div> -->
    </div>
    <div class="confirm-btn">
      <button class="btn" @click="saveCertificate">Sacuvaj</button>
    </div>
    <div :class="{ shakeWarn: isShaking }" class="notif-label">
        {{ globalMsg === "" ? "" : $t(globalMsg) }}
    </div>
  </div>
</template>

<script setup>
import { useClientsStore } from "@/store/clientsStore";
import { ModalStore } from "@/store/modalStore";
import api from "@/api";

const clientsStore = useClientsStore();
const modalStore = ModalStore();
const certificates = reactive([
  ...modalStore.modalData.modalData.map((el) => {
    return { file: el, name: ref(""), pass: ref(""), pac: ref(""), error: ref(null)};
  }),
]);

const globalMsg = ref("");

async function saveCertificate() {
  resetError();
  let fd = new FormData();
  try {
    certificates.forEach((el) => {
      fd.append("certificate_files", el.file);
      fd.append(
        "certificate_info",
        JSON.stringify({ name: el.cert_name, pass: el.pass, pac: el.pac })
      );
    });
    fd.append("cli_id", clientsStore.currentClient?.cli_id);
    // const params = {
    //   cli_id: clientsStore.currentClient.cli_id,
    //   certificate_files: fd,
    // };
    const res = await api.createCertificate(fd);
    console.log(res.data);
    if(res.data && res.data.failed?.length > 0) {
      globalMsg.value = 'app.main.errCertify'
      setError(res.data.failed);
    } else {
      props.options.update();
      modalStore.setModalData(null);
    }
  } catch (error) {
    console.log(error);
    console.log(error.message)
    console.log(error.response?.request);
    if(error.message === 'Network Error') {
      globalMsg.value= 'app.main.tooBigSize'
    }
    fd = new FormData();
  }
}

function setError(arrErr){
  for(let i=0; i < certificates.length; i++) {
    for( let j=0; j < arrErr.length; j++) {
      if (i === arrErr[j].index 
      && (certificates[i].file.name === arrErr[j].name)){
        certificates[i].error = 'app.main.error'
        globalMsg.value = 'app.main.errCertify'
      }
    }

  }
}
const props = defineProps(["options"]);

const resetError = () =>{
    certificates.forEach((el) => {
       el.error = ''
    });
    globalMsg.value = ''
}

//style
let isShaking = ref(false);
const handleShake = () => {
  isShaking.value = true;
  setTimeout(() => {
    isShaking.value = false;
  }, 1500);
};

watch(
  ()=> globalMsg.value,
  ()=> {
    handleShake();
  }
)

</script>

<style lang="scss" scoped>
.ccc-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 30px;
  .confirm-certificate-content {
    width: 600px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    // input
    .input-wrap {
        width: 100%;
        max-width: 546px;
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        position: relative;
        .input-label {
          margin-bottom: 6px;
          font-weight: 600;
          font-size: 15px;
          line-height: 16px;
          color: #98a0b6;
          position: relative;
          padding-right: 20px;
        }
        .custom-input {
          width: 100%;
          height: $max-input-height;
          border: 1px solid $input-border-idle;
          border-radius: 30px;
          padding: 0 30px;
          font-weight: 600;
          font-size: 16px;
          color: $main-dark;
          line-height: 19px;
          font-family: "Archivo", sans-serif;

          &:focus {
            outline: 2px solid $main-green;
          }
          &:disabled{
            background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
          }

          &::placeholder {
            color: #acacad;
            font-weight: 400;
            font-size: $placeholder-size;
            line-height: 14px;
          }
        }

    }

// end input 
    .info-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
    .file-name {
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 134%;
      color: #344368;
    }
    .cert-name {
      .cert-confirm-input {
        margin: 0 0 25px 0;
      }
    }
    .cert-pass {
      .cert-confirm-input {
        margin: 0 0 25px 0;
      }
    }
  }
  .confirm-btn {
    display: flex;
    justify-content: end;
  }
}
.notif-label {
  min-width: 150px;
  height: 20px;
  text-align: right;
  position: absolute;
  right: 10px;
  top: auto;
  bottom: 0px !important;
  font-size: 13px;
  font-style: italic;
  color: red;
}
</style>
