<template>
  <div class="wrapapiModal scrollbar custom-scollbar">
    <div class="wrapp">
        <InvoiceForModal :data="options.data" />
        <div class="wrpBtn">
            <div 
                class="btn"
                @click="goToListInvoice"
            >
                {{$t('app.invoice-overview.viewInvoice.goToAllInvoice')}}
            </div>
            <div
            v-if="options.data.billId"
             class="btn"
             @click="showMore(options.data.billId)"
             >
                {{$t('app.invoice-overview.viewInvoice.seeMoreInvoiceInfo')}}
            </div>
        </div>
    </div> 

    <!--  -->
     <div 
     v-if="options.refund"
     class="wrapp">
        <InvoiceForModal :data="options.refund" />
        <div class="wrpBtn">
            <div 
                class="btn"
                @click="goToListInvoice"
            >
                {{$t('app.invoice-overview.viewInvoice.goToAllInvoice')}}
            </div>
            <div
            v-if="options.refund.billId"
             class="btn"
             @click="showMore(options.refund.billId)"
             >
                {{$t('app.invoice-overview.viewInvoice.seeMoreInvoiceInfo')}}
            </div>
        </div>
    </div> 
    <!--  -->
  </div>
</template>

<script setup>
// import { useClientsStore } from "@/store/clientsStore";
import { ModalStore } from "@/store/modalStore";
import api from "@/api"

const router = useRouter();
// const clientsStore = useClientsStore();
const modalStore = ModalStore();

const props = defineProps(["options"]);

function goToListInvoice(){
    // props.options.goToList();
     router.push("/");
    modalStore.setModalData(null);
}
function showMore(id){
    // props.options.seeMore()
    router.push({ name: "ViewInvoice", params: { id } });
    modalStore.setModalData(null);
}
</script>

<style lang="scss" scoped>
.wrapapiModal{
     min-width: 400px;
     max-height: 90vh;
     overflow: auto;
     padding-bottom: 30px;
    //  box-shadow: 4px 4px 57px 0px #0000001A;

}
.wrapp{
    margin-bottom: 40px;
    padding-bottom: 40px;
    .wrpBtn{
        // margin-top: 10px;
        display: flex;
        justify-content: space-around;
        .btn{
            font-size: .9em;
        }
    }
   
}
.wrapp:last-of-type{
    border-top: 3px solid  $input-border-idle;
    padding-top: 50px;
}


</style>