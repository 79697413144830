<template>
  <div class="create-item-wrap">
    <div class="title">
      <h2>
        {{ $t("app.item-overview.newItem.title") }}
      </h2>
      <router-link class="return" to="/item-overview" title="return">
        <img src="../../assets/images/icons/return.svg" alt="" />
      </router-link>
    </div>
    <div class="content-wrapper">
      <div class="inputs-wrapp">
        <BaseInput
          v-model="article_name"
          :label="$t(`app.item-overview.newItem.nameLabel`)"
          :notification="article_name_error"
          @input="article_name_error = ''"
        />
        <BaseInput
          v-model="article_price"
          :label="$t(`app.item-overview.newItem.priceLabel`)"
          :regex="'^[0-9]+$'"
          :notification="article_price_error"
          type="number"
          @input="article_price_error = ''"
        />
        <BaseInput
          v-model="article_gtin"
          :label="$t(`app.item-overview.newItem.gtinLabel`)"
          :notification="article_gtin_error"
          @input="article_gtin_error = ''"
        />
        <BaseSelect
          v-if="clientTaxLabels?.length > 0"
          :topLabelText="$t('app.create-invoice.tax')"
          :placeholder="$t('app.create-invoice.tax')"
          :options="getCurrentTaxes"
          :value="taxSelectedForShow"
          :showTopLabel="true"
          :multiple="true"
          :applyWrap="true"
          @selected="selectTax"
          :notification="currentTaxSelectedNotif"
          notifColor="red"
        ></BaseSelect>
        <BaseSelect
          v-if="false"
          :value="selectedTransactionType?.label"
          :options="transactionTypes"
          :placeholder="$t('app.invoice-overview.transType')"
          @selected="setSelectedTransactionType"
        />
        <div class="wrappButton">
          <router-link class="btn return" to="/item-overview" title="return">
            {{ $t("app.create-invoice.cancel") }}
          </router-link>
          <button class="btn save" @click="saveChanges">
            {{ $t("app.item-overview.newItem.saveBtn") }}
          </button>
        </div>
      </div>
      <div class="img-wrapp">
        <img src="../../assets/images/create-img.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import api from "../../api/index.js";
import { useClientsStore } from "../../store/clientsStore";
import { TAX_TYPES, INVOICE_TYPES } from "@/assets/js/types";
import { objectKeysToArr } from "@/assets/js/helpers";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const router = useRouter();

const clientStore = useClientsStore();

onMounted(() => {
  getClientTaxLabels();
});

const onInputArticleName = (value) => (article_name.value = value);
const onInputArticlePrice = (value) => (article_price.value = value);
const onInputArticleGtin = (value) => (article_gtin.value = value);
const onInputArticleTax = (value) => (article_tax_labels.value = value);
const setSelectedTransactionType = (type) => {
  selectedTransactionType.value = {
    id: TAX_TYPES[type],
    label: type,
  };
};

let selectedTransactionType = ref(null);
let article_name = ref("");
let article_price = ref("");
let article_gtin = ref("");
let article_tax_labels = ref([]);

let article_name_error = ref("");
let article_price_error = ref("");
let article_gtin_error = ref("");

const transactionTypes = computed(() => {
  return objectKeysToArr(TAX_TYPES);
});

// data for tax
const clientTaxLabels = ref([]);
const currentTaxSelectedNotif = ref();
const taxSelectedForShow = ref([]);

const saveChanges = async () => {
  let error = false;
  try {
    let params = {
      cli_id: clientStore.currentClient.cli_id,
      // art_name: article_name.value,
      // art_gtin: article_gtin.value,
      // art_base_price: article_price.value,
      // // art_tax_labels: article_tax_labels.value,
      // // tax_test: selectedTransactionType.value,
      // art_tax_labels: selectedTransactionType.value,

      // selectedTransactionType - kada bek javi da se ubaci..
    };
    if (article_name.value === "") {
      article_name_error.value = t("app.create-invoice.requiredField");
      error = true;
    }
    if (article_price.value === "") {
      article_price_error.value = t("app.create-invoice.requiredField");
      error = true;
    }
    // if (article_gtin.value === "") {
    //   article_gtin_error.value = t("app.create-invoice.requiredField");
    //   error = true;
    // }
    // set tax
    setTaxForSend();
    if (article_tax_labels.value.length <= 0) {
      currentTaxSelectedNotif.value = t("app.create-invoice.requiredField");
      error = true;
    }
    if (error) {
      return;
    }

    // SET PARAMS
    params.art_name = article_name.value;
    if (article_gtin.value) {
      params.art_gtin = article_gtin.value;
    }
    params.art_base_price = article_price.value;
    params.art_tax_labels = article_tax_labels.value;

    const res = await api.createArticle(params);
    console.log(res);
    router.push("/item-overview");
  } catch (error) {
    console.log(error);
  }
};

// Get tax
const getClientTaxLabels = async () => {
  try {
    let params = {
      cli_id: clientStore.currentClient.cli_id,
    };
    let res = await api.getTaxLabels(params);
    if (res.status === 200) {
      clientTaxLabels.value = res.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};

//settings for TAX
function selectTax(val) {
  taxSelectedForShow.value = val;
  currentTaxSelectedNotif.value = "";
}

const getCurrentTaxes = computed(() => {
  let res = clientTaxLabels.value.map((el) => formatLabelToDispay(el));
  return res;
});
const formatLabelToDispay = (el) => el.label + ": " + el.rate + "%";

function setTaxForSend() {
  article_tax_labels.value = [];
  for (let i = 0; i < taxSelectedForShow.value.length; i++) {
    // let help = taxSelectedForShow.value[i].split(':')
    article_tax_labels.value.push(taxSelectedForShow.value[i].split(":")[0]);
  }
}
</script>

<style lang="scss" scoped>
.create-item-wrap {
  .title {
    margin-bottom: 30px;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    h2 {
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      color: $main-dark;
    }
  }
}
.content-wrapper {
  display: flex;
  .inputs-wrapp {
    // min-width: 250px;
    width: 50%;
    z-index: 1;
    .wrappButton {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .btn {
      margin-top: 20px;
    }
    .return {
      width: 150px;
      height: 38px;
      text-decoration: none;
    }
    .save {
      // width: 150px;
      height: 38px;
    }
  }
  .img-wrapp {
    // width: 100%;
    width: 50%;
    height: 70vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 10%;
    // img {
    //   height: 60%;
    //   object-fit: contain;
    // }
    img {
      width: 92%;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .create-item-wrap {
    .title {
      width: 100%;
      margin: auto;
    }
  }
}

@media only screen and (max-width: 800px) {
  .create-item-wrap {
    .title {
      max-width: 546px;
      width: 100%;
      margin: auto;
      h2 {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
  .content-wrapper {
    flex-direction: column;

    .inputs-wrapp {
      margin-bottom: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .wrappButton {
        max-width: 546px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      > .input-wrap {
        margin-bottom: 20px;
      }
    }
    .img-wrapp {
      width: 100%;
      height: unset;
      justify-content: center;

      img {
        height: unset;
        width: 100%;
      }
    }
  }
}
</style>
