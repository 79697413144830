export const ModalStore = defineStore("ModalSore", () => {
    const modalData = ref(null);

    const getModalData = computed(() => {
        return modalData.value;
    });

    function setModalData(data) {
        modalData.value = data;
    }

    return {
        modalData,
        getModalData,
        setModalData,
    };
});
