<template>
  <div
    class="multiselect-wrap"
    :class="{ 'input-wrap-apply': props.applyWrap }"
  >
    <label v-if="showTopLabel" class="ms-top-label">{{
      props.topLabelText
    }}</label>
    <label
      v-if="notification"
      :class="{ shakeWarn: isShaking }"
      :style="{ color: props.notifColor }"
      class="notif-label"
    >
      {{ notification }}
    </label>
    <MultiSelect
      v-if="!paymentTool"
      v-model="computedValue"
      :placeholder="props.placeholder"
      :options="options"
      :searchable="props.searchable"
      :close-on-select="props.closeOnSelect"
      :multiple="props.multiple"
      :show-labels="props.showLabels"
      :show-no-results="false"
      :loading="loading"
      :taggable="taggable"
      :internal-search="props.searchable"
      :custom-label="handleLabelFormat"
       :disabled="disabled"
      @search-change="onInput"
      @tag="setNewTag"
    >
    </MultiSelect>
    <MultiSelect
      v-if="paymentTool"
      v-model="valueComputed"
      :placeholder="props.placeholder"
      :options="options"
      :searchable="props.searchable"
      :close-on-select="props.closeOnSelect"
      :multiple="props.multiple"
      :show-labels="props.showLabels"
      :show-no-results="false"
      :loading="loading"
      :taggable="taggable"
      :internal-search="props.searchable"
      :custom-label="handleLabelFormat"
      :disabled="disabled"
      @search-change="onInput"
      @tag="setNewTag"
      @select="setNewTagPayment"
      @remove="removeTagPayment"
    >
    </MultiSelect>
  </div>
</template>
<script setup>
import { debounce } from "@/assets/js/helpers";
import MultiSelect from "vue-multiselect";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps({
  notification: {
    type: String,
    required: false,
    default: null,
  },
  notifColor: {
    type: String,
    required: false,
    default: "red",
  },
  taggable: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  searchable: {
    type: Boolean,
    required: false,
    default: false,
  },
  showLabels: {
    type: Boolean,
    required: false,
    default: false,
  },
  placeholder: {
    type: String,
    required: false,
    default: "Set placeholder",
  },
  value: {
    type: [String, Object, Array],
    required: false,
    default: null,
  },
  selectedOptions: {
    type: Array,
    required: false,
  },
  options: {
    type: Array,
    required: true,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  closeOnSelect: {
    type: Boolean,
    default: true,
  },
  showTopLabel: {
    type: Boolean,
    default: false,
  },
  topLabelText: {
    type: String,
    default: "Default label",
  },
  applyWrap: {
    type: Boolean,
    default: false,
  },
  translate: {
    type: Boolean,
    default: false
  },
  paymentTool: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

let isShaking = ref(false);
const computedValue = computed({
  get: () => props.value,
  set: (newValue) => {
    // Emit the "selected" event to update the value in the parent component
    if(!props.paymentTool)
      emits("selected", newValue);
    console.log(newValue)
  },
});
const valueComputed = computed( () => props.value)

const emits = defineEmits(["selected", "onInput", "update:modelValue", "remove"]);

const options = computed(() => [...props.options]);
const loading = computed(() => props.loading);

const setNewTag = (newValue) => {
  console.log('set new tag')
  computedValue.value = newValue;
};
const setNewTagPayment = (e) => {
  console.log('set new tag')
  console.log(e)
  // computedValue.value = newValue;
  // emits("update:modelValue", e);
  emits('selected',e)
};
const removeTagPayment = (e) => {
  console.log('removetag')
  console.log(e)
  //  emits("update:modelValue", e);
  emits('remove',e)
  // computedValue.value = newValue;
};

const onInput = debounce((input) => {
  emits("onInput", input);
});

const handleLabelFormat = (option) => {
  if (typeof option === "object") {
    // I change this because (!option.label || !option.id) is true when id is 0
    if (option.label === undefined || option.id === undefined) { 
      console.warn(
        "CUSTOM MULTISELECT NOT SUPPORTED OBJECT STRUCTURE REQUIRED: {label:'label', id: id} BUT PROVIDED :" +
          option
      );
    }
    return option.label;
  } else {
    return option;
  }
};
const handleShake = () => {
  isShaking.value = true;
  setTimeout(() => {
    isShaking.value = false;
  }, 1500);
};
watch(
  () => props.value,
  (newValue) => {
    // console.log(typeof newValue)
    computedValue.value = newValue;
  },
  // {deep: true}
);
watch(
  () => props.notification,
  () => {
    handleShake();
  }
);
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
.input-wrap-apply {
  margin: 20px 0;
}
.notif-label {
  text-align: right;
  position: absolute;
  right: 10px;
  top: 0;
  top:62px;
  font-size: 13px;
  font-style: italic;
}
.multiselect-wrap {
  width: 100%;
  max-width: 546px;
  display: flex;
  flex-direction: column;
  .multiselect__content-wrapper {
    overflow-y: auto;
    max-height: 200px; /* or any other height you prefer */
  }
  .ms-top-label {
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: #98a0b6;
  }
  .multiselect__spinner {
    background-color: transparent;
  }
  .arrow-ms {
    position: absolute;
    right: 20px;
    top: 9px;
  }
  .arrow-ms-wrapped {
    position: absolute;
    right: 20px;
    top: 30px;
  }
  .multiselect__tags,
  .multiselect__content-wrapper {
    border-radius: 20px !important;
    border: 1px solid $input-border-idle;
    // height: 40px;
   
  }
  // .multiselect__tags-wrap{
  //    display: flex;
  //   flex-direction: row !important;
  //   flex-wrap: nowrap;
  //   overflow:auto;
  //   span{
  //     width: fit-content !important;
  //   }

  // }
  .multiselect--active .multiselect__tags {
    outline: 2px solid $main-green;
  }

  .multiselect__single,
  .multiselect__placeholder {
    color: #acacad;
    font-weight: 400;
    line-height: 14px;
    display: inline-flex;
    align-items: center;
  }
  .multiselect__placeholder {
    color: #acacad;
    font-weight: 400;
    font-size: $placeholder-size;
    line-height: 14px;
  }
  .multiselect__tags {
    padding-left: 23px;
  }
  .multiselect__option {
    padding-left: 25px;
    font-size: 12px;
  }
  .multiselect__option--highlight,
  .multiselect__tag {
    background: $main-green;
  }
  .multiselect__tag {
    margin-bottom: 0;
  }
  .multiselect__single {
    font-weight: 600;
    font-size: 12px;
    color: $main-dark;
    line-height: 14px;
  }
  .multiselect__input {
    font-weight: 600;
    font-size: 12px;
    color: $main-dark;
    line-height: 14px;
  }
}
.multiselect--disabled{
    cursor: not-allowed;
    overflow: hidden;
    background: #fff;

     .multiselect__tags {
      padding-left: 23px;
       background-color: #f6f5f5;
    }
    .multiselect__select, .multiselect__single{
      background: transparent;
    }
    
  }
</style>
