<template>
  <div class="content-section">
    <div class="input-grp">
      <BaseInput
        class="mr-spacing"
        :label="$t('app.create-invoice.cashier')"
        v-model="cachier"
        :notification="cashierNotif"
        @input="() => (cashierNotif = null)"
      ></BaseInput>

      <dropdown-one
        v-model="selectedInoviceType"
        :dropdownLabel="'label'"
        :options="INVOICE_TYPES_NEW"
        class="mt-5 mr-spacing input-wrap-apply"
        :placeholder="$t('app.create-invoice.invoiceType')"
        :label="$t('app.create-invoice.invoiceType')"
        :translate="true"
        :notification="selectedInoviceTypeNotif"
        @optionSelected="setSelectedInoviceTypeNew"
        :disabled='
         store.getInvoiceAction === "addOn-ADVANCE"
         || store.getInvoiceAction === "complete-ADVANCE"
        '
      />

    </div>
    <div class="input-grp">  
      <dropdown-one
        v-model="buyerIdentification"
        :dropdownLabel="'label'"
        :options="BUYER_IDENTIFICATION"
        class="mt-5 mr-spacing input-wrap-apply"
        :placeholder="$t('app.create-invoice.typeIdentification')"
        :label="$t('app.create-invoice.typeIdentification')"
        :translate="false"
        @optionSelected="setSelectedBuyerIdentification"
        :disabled='
         store.getInvoiceAction === "addOn-ADVANCE"
         || store.getInvoiceAction === "complete-ADVANCE"
         || invoice && invoice.buyerId
        '
        :erasable="true"
        @delete="deleteBuyerIdentificationType"
      />

      <BaseInput
        :label="$t('app.create-invoice.buyerIdentification')"
        v-model="buyerId"
        info='Kako biste popunili Identifikaciju kupca, &#013; prvo odaberite "Vrstu identifikacije" iz padajućeg menija, &#013; zatim popunjavanje  "identifikacija kupca" postaje obavezno.'
        :notification="buyerIdNotif"
        @input="() => (buyerIdNotif = null)"
        :disabled='
         !buyerIdentification
         || store.getInvoiceAction === "addOn-ADVANCE"
         || store.getInvoiceAction === "complete-ADVANCE"
        '
      ></BaseInput>
    </div>
    <div class="input-grp">
      <dropdown-one
        v-model="typeOptionalField"
        :dropdownLabel="'label'"
        :options="TYPE_OPTIONAL_FIELD"
        class="mt-5 mr-spacing input-wrap-apply"
        :placeholder="$t('app.create-invoice.typeOptionalField')"
        :label="$t('app.create-invoice.typeOptionalField')"
        :translate="false"
        @optionSelected="setSelectedTypeOptionalField"
        :disabled='
         store.getInvoiceAction === "addOn-ADVANCE"
         || store.getInvoiceAction === "complete-ADVANCE"
         || invoice && invoice.buyerCostCenterId
        '
        :erasable="true"
        @delete="deleteTypeOptionalField"
      />
      
      <BaseInput
        class="mr-spacing"
        :label="$t('app.create-invoice.optionalField')"
        v-model="buyerCostCenterId"
        info='Kako biste popunili Opciono polje kupca, &#013; prvo odaberite "Vrsta opcionog polja" iz padajućeg menija, &#013; zatim popunjavanje  "Opciono polje kupca" postaje obavezno.'
        @input="() => (buyerCostIdNotif = null)"
        :notification="buyerCostIdNotif"
        :disabled='
        !typeOptionalField
         || store.getInvoiceAction === "addOn-ADVANCE"
         || store.getInvoiceAction === "complete-ADVANCE"
        '
      ></BaseInput>
      <!-- <BaseSelect
        :topLabelText="$t('app.create-invoice.invoiceType')"
        :placeholder="$t('app.create-invoice.invoiceType')"
        :options="invoiceTypes"
        :showTopLabel="true"
        :applyWrap="true"
        :value="selectedInoviceType"
        :notification="selectedInoviceTypeNotif"
        @selected="setSelectedInoviceType"
      ></BaseSelect> -->
     
    </div>
    <div class="input-grp">
      <BaseInput
        class="mr-spacing"
        :label="$t('app.create-invoice.referentNumber')"
        info="PFR broj računa na koji se ovaj račun odnosi"
        v-model="referentDocumentNumber"
        @input="() => (referentDocumentNumberNotif = null)"
        :notification="referentDocumentNumberNotif"
        :disabled='
         store.getInvoiceAction === "addOn-ADVANCE"
         || store.getInvoiceAction === "complete-ADVANCE"
        '
      ></BaseInput>
      <!-- :disabled="selectedTransactionType?.label === 'SALE'? true : false" -->
      <!-- <BaseInput
        :label="$t('app.create-invoice.referentPfrTime')"
        v-model="referentDocumentDT"
        @input="(val) => (referentDocumentDT = val)"
      ></BaseInput> -->
      <div class="wrappInput">
        <label class="input-label" for="input">
          {{$t('app.create-invoice.referentPfrTime')}}
          <span 
          class="info"
          title="PFR datum računa na koji se ovaj račun odnosi"
          >?</span>
        </label>
        <DateInput 
          v-model="referentDocumentDT"
          :label="$t('app.create-invoice.referentPfrTime')"
          style="min-width:180px; width:100%; text-align:left"
          @input="(val) => {referentDocumentDT = val; referentDocumentDTNotif= null}"
          :placeholder="$t('app.create-invoice.referentPfrTime')" 
          type="datetime-local"
          :notification="referentDocumentDTNotif"
          :disabled='
            store.getInvoiceAction === "addOn-ADVANCE"
            || store.getInvoiceAction === "complete-ADVANCE"
          '
        />
      </div>
    </div>
    <div class="input-grp">
      <!-- <BaseSelect
        class="mr-spacing"
        :topLabelText="$t('app.create-invoice.transactionType')"
        :placeholder="$t('app.create-invoice.transactionType')"
        :options="transactionTypes"
        :showTopLabel="true"
        :applyWrap="true"
        :value="selectedTransactionType"
        @selected="setSelectedTransactionType"
        :notification="selectedTransactionTypeNotif"
      ></BaseSelect> -->
      <dropdown-one
        v-model="selectedTransactionType"
        :dropdownLabel="'label'"
        :options="TRANSACTION_TYPE_NEW"
        class="mt-5 mr-spacing input-wrap-apply"
        :placeholder="$t('app.create-invoice.transactionType')"
        :label="$t('app.create-invoice.transactionType')"
        :translate="true"
        :notification="selectedTransactionTypeNotif"
        @optionSelected="setSelectedTransactionTypeNew"
        :disabled='
         store.getInvoiceAction === "addOn-ADVANCE"
         || store.getInvoiceAction === "complete-ADVANCE"
        '
      />
      
      <BaseSelect
        :topLabelText="$t('app.create-invoice.paymentMethod')"
        :placeholder="$t('app.create-invoice.paymentMethod')"
        :options="paymentMethods()"
        :value="selectedPaymentMethods"
        :showTopLabel="true"
        :multiple="true"
        :applyWrap="true"
        @selected="setSelectedPaymentMethods"
        @remove="removeSelectedPamentMetods"
        :notification="selectedPaymentMethodsNotif"
        :taggable="true"
        :paymentTool="true"
        handleLabelFormat="label"
      ></BaseSelect>
    </div>
     <!-- <div class="input-grp">
      
     </div>  -->

    <div class="pay-tool">
      <div 
       v-if="selectedPaymentMethods.length > 0" 
       class="pay-header">
        <div class="pay-header-method">
          {{ $t("app.create-invoice.paymentMethod") }}
        </div>
        <div class="pay-header-amount">
          {{ $t("app.create-invoice.amount") }}
        </div>
      </div>
      <div
        class="pay-data"
        v-for="(paymentMethod, index) in selectedPaymentMethods"
        :key="index"
      >
        <div class="pay-data-name">{{ paymentMethod.label }}</div>
        <div class="pay-data-input">
          <BaseInput 
            :label="''" 
            v-model="selectedPaymentMethods[index].amount"
            :notification="selectedPaymentMethods[index].error"
            @input="() => (selectedPaymentMethods[index].error='')"
            type="number"
            :min="0"
          />
        </div>
        <div class="pay-data-valute">RSD</div>
        <div class="pay-data-close" @click="removePayment(index)">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.00569 6.35263L8.37334 9.72091C8.55204 9.89961 8.79441 10 9.04713 10C9.29984 10 9.54221 9.89961 9.72091 9.72091C9.89961 9.54221 10 9.29984 10 9.04713C10 8.79441 9.89961 8.55204 9.72091 8.37334L6.35199 5.0057L9.72027 1.63806C9.80871 1.54957 9.87886 1.44454 9.92671 1.32895C9.97456 1.21336 9.99917 1.08947 9.99914 0.964367C9.99911 0.839264 9.97444 0.715391 9.92654 0.599822C9.87864 0.484252 9.80844 0.37925 9.71996 0.290809C9.63147 0.202368 9.52644 0.132222 9.41085 0.0843737C9.29525 0.0365259 9.17137 0.011914 9.04627 0.0119435C8.92116 0.011973 8.79729 0.0366431 8.68172 0.0845454C8.56615 0.132448 8.46115 0.202644 8.37271 0.291127L5.00569 3.65877L1.63805 0.291127C1.55022 0.200106 1.44514 0.127489 1.32895 0.0775116C1.21276 0.0275345 1.08777 0.00119826 0.961292 3.99467e-05C0.834811 -0.00111836 0.709367 0.0229242 0.592278 0.070765C0.475188 0.118606 0.368799 0.189287 0.279318 0.278683C0.189837 0.36808 0.119056 0.474402 0.0711052 0.591446C0.023154 0.70849 -0.0010069 0.833912 3.2144e-05 0.960393C0.00107119 1.08687 0.0272893 1.21188 0.0771569 1.32812C0.127025 1.44436 0.199543 1.54951 0.29048 1.63742L3.6594 5.0057L0.291116 8.37334C0.112417 8.55204 0.0120254 8.79441 0.0120254 9.04713C0.0120254 9.29984 0.112417 9.54221 0.291116 9.72091C0.469814 9.89961 0.712181 10 0.964899 10C1.21762 10 1.45998 9.89961 1.63868 9.72091L5.00569 6.352V6.35263Z"
              fill="#9C9C9C"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useClientsStore } from "@/store/clientsStore";
import api from "@/api"
import { objectKeysToArr, TWO_DECIMAL_REGEX } from "@/assets/js/helpers";
import {
  INVOICE_TYPES,
  INVOICE_TYPES_NEW,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_NEW,
  TAX_TYPES,
  PAYMENT_METHODS,
  PAYMENT_METHODS_NEW,
  RESTRICT_FOR_BACKERY
} from "@/assets/js/types";
import { useI18n } from "vue-i18n";
import { defaultStore } from "@/store/index.js"
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
  dayjs.extend(utc);

const clientsStore = useClientsStore();
const store = defaultStore();
const { t } = useI18n();
const emit = defineEmits(["paymentData", "setData", "selectInvoiceType"]);
const props = defineProps({
  invoice:{}

});

const buyerIdentification = ref(null);
const BUYER_IDENTIFICATION = ref([
  { id:10, label: "PIB" },
  { id:11, label: "JMBG" },
  { id:12, label: "PIB i JBKJS kupca (PIB:JBKJS)" },
  { id:20, label: "Broj lične karte Domaće fizičko lice" },
  { id:21, label: "Broj izbegličke legitimacije" },
  { id:22, label: "EBS  Strano fizičko lice koje ima prijavljeni boravak u Srbiji" },
  { id:23, label: "Broj pasoša Domaće fizičko lice" },
  { id:30, label: "Broj pasoša Strano fizičko lice" },
  { id:31, label: "Broj diplomatkse legitimacije/LK" },
  { id:32, label: "Broj lične karte MKD" },
  { id:33, label: "Broj lične karte MNE" },
  { id:34, label: "Broj lične karte ALB" },
  { id:35, label: "Broj lične karte BIH" },
  { id:36, label: "Broj lične karte po Odluci Strano fizičko lice" },
  { id:40, label: "Poreski ID iz strane države (TIN)"},
]);

const typeOptionalField = ref(null);
const TYPE_OPTIONAL_FIELD = ref([
{ id:20, label: "Broj SNPDV"},
{ id:21, label: "Broj LNPDV"},
{ id:30, label: "Broj PPO-PDV"},
{ id:31, label: "Broj ZPPO-PDV"},
{ id:32, label: "Broj MPPO-PDV"},
{ id:33, label: "Broj IPPO-PDV"},
{ id:50, label: "Broj korporacijske kartice"},
{ id:60, label: "Vremenski period - korporacijske kartice"},
]);


// this dont work
onMounted(() => {
  if (props.invoice) {
    console.log(props.invoice)
  }
})
watch(
  ()=> props.invoice,
  ()=>{
    console.log(props.invoice)
    setOldData(props.invoice)
  }
)

//payment tool

let cachier = ref(null);
let buyerId = ref(null);
//This is optional field tf
let buyerCostCenterId = ref(null);
let selectedInoviceType = ref();
let referentDocumentNumber = ref(null); // pfr number
let referentDocumentDT = ref(null); //pfr date-time
let selectedTransactionType = ref();
let selectedPaymentMethods = ref([]);
// let paymentMethodsValue = ref([]);

let selectedInoviceTypeNotif = ref();
let selectedTransactionTypeNotif = ref();
let selectedPaymentMethodsNotif = ref();
// refund notif
const referentDocumentNumberNotif = ref();
const referentDocumentDTNotif = ref();
const buyerIdNotif = ref();
const cashierNotif = ref();
const buyerCostIdNotif = ref();


// treba doraditi za apk_id
const keyList = ref([])

function emitData() {
  valdiateForm()
}
function setParam(){
/*
                 dateAndTimeOfIssue,    // treba videti kasnije da se ubaci
      -- invoiceType,
      -- transactionType,
      -- cashier,
      -- buyerId,
      -- buyerCostCenterId,
      -- referentDocumentNumber,
      -- referentDocumentDT,
             items                // from another component - ubacila kroz parenta i pomocne komoonente
      -- payment,
      apk_id
              cli_id                // from parent component - ubacila kroz parenta 
*/

  let obj = {
    payment: selectedPaymentMethods.value,
    invoiceType: selectedInoviceType.value,
    transactionType: selectedTransactionType.value,
    cashier: cachier.value,
    // buyerId: buyerId.value,
    // buyerCostCenterId: buyerCostCenterId.value,
    referentDocumentNumber: referentDocumentNumber.value,
    referentDocumentDT: referentDocumentDT.value,
  };
  if(typeOptionalField.value?.id) {
    obj.buyerCostCenterId = typeOptionalField.value.id +':'+ buyerCostCenterId.value;
  } else {
    obj.buyerCostCenterId =  buyerCostCenterId.value;
  }
  if(buyerIdentification.value?.id) {
    obj.buyerId = buyerIdentification.value.id + ':' +buyerId.value;
  } else {
    obj.buyerId = buyerId.value;
  }



  console.log(obj);
  emit('setData', obj);
  return obj;
}

const valdiateForm = () => {
  let isCorrect = true;
  resetNotifs();
  // historical moment of conditions, it's a shame to change it :)
  if (!selectedInoviceType.value || !selectedInoviceType.value) {
    selectedInoviceTypeNotif.value = t("app.create-invoice.requiredField");
    isCorrect = false;
  }
  if (!selectedTransactionType.value || !selectedTransactionType.value) {
    selectedTransactionTypeNotif.value = t("app.create-invoice.requiredField");
    isCorrect = false;
  }
  if (!selectedPaymentMethods.value[0] || !selectedPaymentMethods.value[0]) {
    selectedPaymentMethodsNotif.value = t("app.create-invoice.requiredField");
    isCorrect = false;
  }
  else if(selectedPaymentMethods.value.length > 0) {
    for(let i = 0; i < selectedPaymentMethods.value.length; i++ ) {
      if(selectedPaymentMethods.value[i].amount === ''){
        isCorrect = false;
        selectedPaymentMethods.value[i].error = t("app.create-invoice.requiredField");
      }
    }
  }

  // set type condition
  if(buyerIdentification.value && !buyerId.value) {
      buyerIdNotif.value = t("app.create-invoice.requiredField");
      isCorrect = false;
  }
  if(typeOptionalField.value && !buyerCostCenterId.value) {
      buyerCostIdNotif.value = t("app.create-invoice.requiredField");
      isCorrect = false;
  }


  if(selectedTransactionType.value?.label === 'REFUND') {
    // referentDocumentNumber,
    // referentDocumentDT,
    // cashier,
    // buyerID		
      if (!buyerId.value) {
        buyerIdNotif.value = t("app.create-invoice.requiredField");
        isCorrect = false;
      }
      if (!cachier.value) {
        cashierNotif.value = t("app.create-invoice.requiredField");
        isCorrect = false;
      }
      if (!referentDocumentDT.value) {
        referentDocumentDTNotif.value = t("app.create-invoice.requiredField");
        isCorrect = false;
      }
      if (!referentDocumentNumber.value) {
        referentDocumentNumberNotif.value = t("app.create-invoice.requiredField");
        isCorrect = false;
      }
  }

  if(selectedInoviceType.value?.label === "COPY") {
      if (!referentDocumentNumber.value) {
        referentDocumentNumberNotif.value = t("app.create-invoice.requiredField");
        isCorrect = false;
      }
  }

  if(selectedInoviceType.value?.label === "ADVANCE") {
      if (!buyerId.value) {
        buyerIdNotif.value = t("app.create-invoice.requiredField");
        isCorrect = false;
      }
  }
  


  if(isCorrect){
    setParam()
  } else {
    emit('setData', false);
  }
};

const resetNotifs = () => {
  selectedInoviceTypeNotif.value = null;
  selectedTransactionTypeNotif.value = null;
  selectedPaymentMethodsNotif.value = null;
 resetNotifForRefund()
};

const resetNotifForRefund = () => {
  referentDocumentNumberNotif.value = null;
  referentDocumentDTNotif.value = null;
  buyerIdNotif.value = null;
  cashierNotif.value = null;
  buyerCostIdNotif.value = null;
};

const deleteTypeOptionalField = () => {
  typeOptionalField.value = null;
  buyerCostIdNotif.value = null;
};
const deleteBuyerIdentificationType = () => {
  buyerIdentification.value = null;
  buyerIdNotif.value = null;
};

const removePayment = (index) => {
  selectedPaymentMethods.value.splice(index, 1);
};
const setSelectedPaymentMethods = (val) => {
  selectedPaymentMethodsNotif.value = ""
  selectedPaymentMethods.value.push(val)
}
const removeSelectedPamentMetods = (val) =>{
  console.log('remove')
  let x = selectedPaymentMethods.value.findIndex((item) => item.id === val.id)
  selectedPaymentMethods.value.splice(x, 1)
}

const setSelectedInoviceType = (val) => {
  selectedInoviceTypeNotif.value = ""
  console.log(val)
  selectedInoviceType.value = val
}
const setSelectedInoviceTypeNew = (val) => {
  selectedInoviceTypeNotif.value = ""
  resetNotifs()
  console.log('type')
  console.log(val)
  // selectedInoviceType.value = val
  if(val.label === 'ADVANCE'){
    emit('selectInvoiceType', 'ADVANCE');
  } else {
    // emit('selectInvoiceType', false);
    emit('selectInvoiceType', val.label);
  }
  selectedInoviceType.value = val
}
// old 
const setSelectedTransactionType = (val) => {
  selectedTransactionTypeNotif.value = ""
  console.log(val)
  selectedTransactionType.value = val
  if(selectedTransactionType.value === 'SALE') {
    // referentDocumentNumber.value = ''
    resetNotifForRefund()
  }
}
const setSelectedTransactionTypeNew = (val) => {
  selectedTransactionTypeNotif.value = ""
  console.log(val)
  selectedTransactionType.value = val
  if(selectedTransactionType.value.label === 'SALE'
  || selectedTransactionType.value.id === 0) {
    // referentDocumentNumber.value = ''
    resetNotifForRefund()
  }
}

const setSelectedBuyerIdentification = (val) => {
  // selectedBuyerIdentificationNotif.value = "";
  console.log(val);
  buyerIdentification.value = val;
};
const setSelectedTypeOptionalField = (val) => {
  // selectedTypeOptionalField.value = "";
  console.log(val);
  typeOptionalField.value = val;
};

const transactionTypes = computed(() => {
  return objectKeysToArr(TRANSACTION_TYPE);
});
const invoiceTypes = computed(() => {
  return objectKeysToArr(INVOICE_TYPES);
});

// const paymentMethods = computed(() => {
//   // console.log(PAYMENT_METHODS)
//   let x = []
//   for(const item in PAYMENT_METHODS){
//     x.push({
//         label: item,
//         id: PAYMENT_METHODS[item],
//         paymentType: PAYMENT_METHODS[item],
//         amount: '',
//         error: ''
//       })
//   }
//   // return objectKeysToArr(PAYMENT_METHODS);
//   return x
// });

const paymentMethods = () => {
  // console.log(PAYMENT_METHODS)
  let x = []
  for(const item in PAYMENT_METHODS_NEW){
    if(clientsStore.currentClient.cli_is_bakery
    && RESTRICT_FOR_BACKERY.includes(PAYMENT_METHODS_NEW[item].id)) {
      console.log(PAYMENT_METHODS_NEW[item].id)
    } else  {
      x.push({
          label: PAYMENT_METHODS_NEW[item].label,
          id: PAYMENT_METHODS_NEW[item].id,
          paymentType: PAYMENT_METHODS_NEW[item].id,
          amount: '',
          error: ''
        })
    }
  }
  // return objectKeysToArr(PAYMENT_METHODS);
  return x
};


// DORADITI KADA BEK BUDE ZNAO STA TACNO TREBA 
async function getApiKey(){
    if(!clientsStore.currentClient)
  return
     let params = {
      cli_id: clientsStore.currentClient.cli_id,
      count: 100,
      // search_by_key: searchByKey.value,
    };
    try {
        
    let res = await api.getKey(params);
    if (res.status === 200) {
      // keyList.value = res.data.data;
    }
    } catch (error) {
        
    }
}
getApiKey()

function setDataForSelect(data){
  for(let i = 0; i < data.length; i++) {
    keyList.value.push({
      id: data[i].apk_id,
      label: data[i].apk_description
    })
  }
}

function resetField(){
    // apk_id - reset kada ga dodamo
    // selectedPaymentMethods.value = []
    selectedPaymentMethods.value = []
    console.log(selectedPaymentMethods.value.length)
    selectedInoviceType.value = null
    selectedTransactionType.value = null
    cachier.value = null
    buyerId.value = null
    buyerCostCenterId.value = null
    referentDocumentNumber.value = null
    referentDocumentDT.value = null;
    buyerIdentification.value = null;
    typeOptionalField.value = null;

    resetNotifs()
}




function setOldData (data) {
  // selectedPaymentMethods.value = data. 
  console.log('data.payment++++++++++')

  console.log(data)
  if(store.getInvoiceAction === "COPY"){
    if(data.payment){
      selectedPaymentMethods.value= setOldPayment(data.payment)
     }
  }
 
  let invTyp = (store.getInvoiceAction !== "COPY")
              ? INVOICE_TYPES_NEW.find((item) => item.id === data.invoiceType)
              : INVOICE_TYPES_NEW.find((item) => item.label === "COPY");

  setSelectedInoviceTypeNew(invTyp)

  selectedTransactionType.value = (store.getInvoiceAction.startsWith ("refund")) 
                                  ? TRANSACTION_TYPE_NEW.find((item) => item.label === "REFUND") 
                                  : TRANSACTION_TYPE_NEW.find((item) => item.id === data.transactionType);
  cachier.value = data.cashier
  buyerId.value = data.buyerId
  buyerCostCenterId.value = data.buyerCostCenterId
  referentDocumentNumber.value = data.invoiceNumber // data.referentDocumentNumber
  referentDocumentDT.value = dayjs.utc(data.billDate).format('YYYY-MM-DDTHH:mm:ss')
  // console.log(dayjs.utc(data.billDate).format('YYYY-MM-DDTHH:mm:ss'))
}

function setOldPayment(payment) {
  let x = []
  for(const item in PAYMENT_METHODS_NEW){
    for(let i = 0; i < payment.length; i++) {
      if(PAYMENT_METHODS_NEW[item].id === payment[i].paymentType){
         x.push({
          label: PAYMENT_METHODS_NEW[item].label,
          id: PAYMENT_METHODS_NEW[item].id,
          paymentType: PAYMENT_METHODS_NEW[item].id,
          amount: payment[i].amount,
          error: ''
        })
      }
    }
   
  }
  return x
}



defineExpose({
  emitData,
  resetField,
});
</script>
<style scoped lang="scss">
.content-section {
  min-width: 400px;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  margin-right: 40px;

  .input-grp {
    display: flex;
    .input-wrap,
    .multiselect-wrap,
    .dropdown-one-wrapper 
    {
      // margin-right: $spacing-margine;
      max-width: 265px !important;
       width:100%;
    }
  }

  .pay-tool {
    .pay-header {
      display: flex;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      color: #98a0b6;
      .pay-header-method {
        max-width: 165px;
        width: 100%;
      }
    }
    .pay-data {
      display: flex;
      max-width: 100%;
      color: $main-dark;
      font-weight: 600;
      .input-wrap {
        margin: 10px auto;
      }
      .pay-data-name {
        width: 100%;
        max-width: 150px;
        display: flex;
        align-items: center;
        margin-right: $spacing-margine;
      }
      .pay-data-input {
        max-width: 170px;
      }
      .pay-data-valute {
        display: flex;
        align-items: center;
        margin-left: $spacing-margine;
        margin-right: auto;
      }
      .pay-data-close {
        display: flex;
        align-items: center;
        margin-left: $spacing-margine;
      }
    }
  }
}
.wrappInput{
   display: flex;
   flex-direction: column;
   margin: 20px 0px 20px 0;
   min-width: calc(50% - 15px);

   .datepicker-wrap{
    justify-content: flex-start;
    padding-left: 23px;
    .datepicker-toggle{
      // text-align: left;
    }
   }
}
.input-label {
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: #98a0b6;
    .info{
      position: absolute;
      bottom: 0;
      right: 0;
      display: inline-block;
      width: 18px;
      height: 18px;
      text-align: center;
      border: 1px solid $main-green;
      border-radius: 50%;
      color: $main-green;
      font-weight:bolder;
    }
  }
</style>
<style lang="scss">
.pay-data-input{
  .input-wrap{
    position: relative;
    .notif-label {
      text-align: right;
      position: absolute;
      right: 10px;
      top: 48px !important;
      font-size: 13px;
      font-style: italic;
    }
  }
}

</style>
