<template>
  <div class="edit-item-wrap">
    <div class="title">
      <h2>
        {{ $t("app.item-overview.editItem.title") }}
      </h2>
       <router-link class="return" to="/item-overview" title="return">
             <img src="../../assets/images/icons/return.svg" alt="">
      </router-link>
    </div>
    <div class="content-wrapper">
      <div class="inputs-wrapp">
        <BaseInput
          v-model="article.art_name"
          :label="$t(`app.item-overview.editItem.nameLabel`)"
        />
        <BaseInput
          v-model="article.art_base_price"
          type="number"
          :label="$t(`app.item-overview.editItem.priceLabel`)"
        />
        <BaseInput
          v-model="article.art_gtin"
          :label="$t(`app.item-overview.editItem.gtinLabel`)"
        />
        <!-- <BaseInput
          v-model="article_tax"
          :label="$t(`app.item-overview.editItem.taxLabel`)"
          @input="onInputArticleTax"
        /> -->
        <BaseSelect
          v-if="clientTaxLabels?.length > 0"
          :topLabelText="$t('app.create-invoice.tax')"
          :placeholder="$t('app.create-invoice.tax')"
          :options="getCurrentTaxes"
          :value="taxSelectedForShow"
          :showTopLabel="true"
          :multiple="true"
          :applyWrap="true"
          @selected="selectTax"
          :notification="currentTaxSelectedNotif"
        ></BaseSelect> 

        <div class="wrappButton">
          <router-link class="btn return"  to="/item-overview" title="return">
             {{ $t("app.create-invoice.cancel") }}
          </router-link>
          <button class="btn save" @click="editArticle">
              {{ $t("app.item-overview.editItem.saveBtn") }}
          </button>
        </div>
        
      </div>
      <div class="img-wrapp">
        <img src="../../assets/images/create-img.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import api from "../../api/index.js";
import { useClientsStore } from "../../store/clientsStore";
import { useArticleStore } from "../../store/articleStore"
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const route = useRoute();

const router = useRouter();

const clientStore = useClientsStore();
const articleStore = useArticleStore();

onMounted(() => {
  setData(articleStore.getArticleData())
  getClientTaxLabels();
});

const article = ref({
    art_id: "",
    art_name: "",
    art_gtin: "",
    art_base_price: "",
    art_base_labels: [],
})

// data for tax
const clientTaxLabels = ref([]);
const currentTaxSelectedNotif = ref();
const taxSelectedForShow = ref([])


const onInputArticleName = (value) => (article_name.value = value);
const onInputArticlePrice = (value) => (article_price.value = value);
const onInputArticleGtin = (value) => (article_gtin.value = value);
const onInputArticleTax = (value) => (article_tax.value = value);

let article_name = ref("");
let article_price = ref("");
let article_gtin = ref("");
let article_tax = ref("");

const updateArticle = async () => {
  // alert("Changes saved!");
  // router.push("/item-overview");
  // console.log(useClientStore);
  console.log(route.params.id)
  try {
    let params = {
      id: 80,
      cli_id: clientStore.currentClient.cli_id,
      art_name: article_name.value,
      art_gtin: article_gtin.value,
      art_tax_labels: article_tax.value,
    };
    const res = await api.editArticle(params);
    console.log(res);
  } catch (error) {
    console.log(error);
  }
};


async function editArticle() {
  let data = articleStore.getArticleData()
  let send = false;
  setTaxForSend()

  let params = {
    id: route.params.id,
    cli_id: clientStore.currentClient.cli_id,
  }

  if (article.value.art_base_labels.length === 0) {
      currentTaxSelectedNotif.value = t("app.create-invoice.requiredField");
      console.log("Missing article data!");
    return
  } else if(article.value.art_base_labels !== data.art_base_labels){
    params.art_base_labels = article.value.art_base_labels
    send = true
  }


  for (const property in article.value) {
    if (
      article.value[property] !== data[property] 
      && article.value[property] !== ''
      && property !== 'art_base_labels'
      ) {
        send = true
        params[property] = article.value[property]
    }
  }
  
  
  console.log(params)
  if(send){
    try {
    const res = await api.editArticle(params);
    console.log(res.data.res);
    if(res.data.res === "ok") {
      articleStore.setArticleData({})
      router.push("/item-overview");
    }
    } catch (e) {
      console.log(e)
    }
  }
}


function setData (data){
  console.log(data)
  if(!data.art_id || data.art_id === "") {
    router.push("/item-overview");
  }
  for (const property in article.value) {
    if (Object.hasOwnProperty.call(data, property)) {
        if (property === 'art_base_labels') {
            article.value[property] = JSON.parse(JSON.stringify(data[property]));
        } else {
          console.log(data[property])
          article.value[property] = data[property]
        } 
    } else {
        if (property === 'art_base_labels') {
            article.value[property] = []
        } else {
          article.value[property] = ""
        }
    }
  }

}


const getClientTaxLabels = async () => {
  try {
    let params = {
      cli_id: clientStore.currentClient.cli_id,
    };
    let res = await api.getTaxLabels(params);
    if (res.status === 200) {
      clientTaxLabels.value = res.data.data;
      setSelectedTaxForDisplay();
   }
  } catch (error) {
    console.error(error);
  }
};

//settings for TAX
function selectTax(val){
  taxSelectedForShow.value = val
  currentTaxSelectedNotif.value = ""
}
const getCurrentTaxes = computed(() => {
  if(!clientTaxLabels.value || clientTaxLabels.value.length <= 0){
    return '';
  }
  let res = clientTaxLabels.value.map((el) => formatLabelToDispay(el));
  return res;
});
const formatLabelToDispay = (el) => el.label + ": " + el.rate + "%";

function setSelectedTaxForDisplay (){
  if(!clientTaxLabels.value || clientTaxLabels.value?.length <= 0) {
    return;
  }
  for( let i = 0; i < clientTaxLabels.value.length; i++) {
    if(article.value.art_base_labels.includes(clientTaxLabels.value[i].label)){
      taxSelectedForShow.value.push(formatLabelToDispay(clientTaxLabels.value[i]))
    } 
  }
}

function setTaxForSend(){
  article.value.art_base_labels= []
  for(let i=0; i < taxSelectedForShow.value.length; i++ ){
    let help = taxSelectedForShow.value[i].split(':')
    // if(article.value.art_base_labels.includes(help[0])){
      
    // }
    article.value.art_base_labels.push(help[0])
  }
}

</script>

<style lang="scss" scoped>
.edit-item-wrap {
  .title {
    margin-bottom: 30px;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    h2 {
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      color: $main-dark;
    }
  }
}
.content-wrapper {
  display: flex;
  .inputs-wrapp {
    // min-width: 250px;
     width: 50%;
    z-index: 1;
    .wrappButton{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

    }
    .btn{
      margin-top: 20px;
    }
    .return {
      width: 150px;
      height: 38px;
      text-decoration: none;
    }
    .save {
      // width: 150px;
      height: 38px;
    }
  }
  .img-wrapp {
    // width: 100%;
    width: 50%;
    height: 70vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 10%;
    // img {
    //   height: 60%;
    //   object-fit: contain;
    // }
    img {
        width: 92%;
    }
  }
}
@media only screen and (max-width: 1100px) {
  .edit-item-wrap {
    .title {
      width: 100%;
      margin: auto;
    }
  }
}

@media only screen and (max-width: 800px) {
  .edit-item-wrap {
    .title {
      max-width: 546px;
      width: 100%;
      margin: auto;
      h2 {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
  .content-wrapper {
    flex-direction: column;

    .inputs-wrapp {
      margin-bottom: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .wrappButton{
        max-width: 546px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

      }

      > .input-wrap {
        margin-bottom: 20px;
      }
    }
    .img-wrapp {
      width: 100%;
      height: unset;
      justify-content: center;

      img {
        height: unset;
        width: 100%;
      }
    }
  }
}
</style>
