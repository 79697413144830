<template>
  <div class="single-item-invice" >
    
    <div 
    v-if="invoice.bll_journal"
    class="item-image">
        <pre>
            <!-- {{splitBill}} -->
            {{setImgofInvoice(invoice.bll_journal)}}
        </pre>
    </div>
    <div v-else class="item-image">
        <img src="../../assets/images/icons/imgInvoce.svg" alt="" />
    </div>
    <div class="item-name">{{ invoice.bll_invoice_number }}</div>
    <div class="item-info">{{ invoice.bll_date }}</div>
</div> 
</template>

<script setup>

const props = defineProps(['invoice'])

const splitBill = ref();
function setImgofInvoice( text ) {

    let arrJournal = text.split("========================================")
    let firstPart = ''
    let secondPart = ''
    if (arrJournal[1]) {
        let help = arrJournal[1].split("----------------------------------------")
        firstPart = help[1] ? help[1] : help
    }
    secondPart = arrJournal[arrJournal.length-2]
    
    // splitBill.value = firstPart + '\r\n======================================== \r\n' + secondPart
    return firstPart + '\r\n======================================== \r\n' + secondPart
   
}
// onMounted(()=>{
//     if(props.invoice.bll_journal)
//         setImgofInvoice(props.invoice.bll_journal)
// })

</script>

<style lang="scss" scoped>
  .single-item-invice{
    width: 260px;
      display: flex;
      flex-direction: column;
      margin: 0 3px 30px 3px;
      cursor: pointer;
      .item-image {
        width: 260px;
        height: 150px;
        border: 1px solid #e1e3e7;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 3px;
        img {
        //   width: 100%;
        //   height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
        pre{
            scale: 0.6;
        }
      }
      .item-name {
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #39393a;
        
      }
      .item-info {
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #39393a;
      }
    }

</style>