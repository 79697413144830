<template>
  <div>
    <div class="k">
      <MultiSelect :placeholder="'test'" :options="arr"> </MultiSelect>
    </div>
  </div>
</template>

<script setup>
import MultiSelect from "vue-multiselect";

const arr = ref([
  "Select option",
  "options",
  "selected",
  "multiple",
  "label",
  "searchable",
  "clearOnSelect",
  "hideSelected",
  "maxHeight",
  "allowEmpty",
  "showLabels",
  "onChange",
  "touched",
]);
</script>

<style lang="scss" scoped></style>
