<template>
  <div ref="turnstile"></div>
</template>

<script lang="ts" setup>
const turnstileSrc = "https://challenges.cloudflare.com/turnstile/v0/api.js";
const turnstileLoadFunction = "cfTurnstileOnLoad";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    turnstile: any;
    [turnstileLoadFunction]: any;
  }
}

declare interface IVueTurnstileData {
  resetTimeout?: ReturnType<typeof setTimeout>;
  widgetId?: string;
}

let turnstileState =
  typeof window === "undefined"
    ? "unloaded"
    : window.turnstile === undefined
    ? "unloaded"
    : "ready";
let turnstileLoad: {
  resolve: (value?: unknown) => void;
  reject: (value?: unknown) => void;
};

type TTurnStile = {
  siteKey?: string; // Generated from the dashboard
  resetInterval?: number;
  size?: "normal" | "compact";
  theme?: "light" | "dark" | "auto";
  language?: string;
  action?: string;
  appearance?: "always" | "execute" | "interaction-only";
  renderOnMount?: boolean;
  resetToken?: boolean;
};

const props = withDefaults(defineProps<TTurnStile>(), {
  siteKey: "0x4AAAAAAAVvINrmUwwkw2b3",
  resetInterval: 295_000, // Get a fresh token after reset-interval milliseconds, Turnstile tokens only last for 5 minute
  theme: "light",
  size: "normal",
  language: "auto",
  action: "", // A customer value that can be used to differentiate widgets under the same sitekey in analytics and which is returned upon validation.
  appearance: "always", // Appearance controls when the widget is visible, only if not set "invisible" in the widget type in dashboard
  renderOnMount: true,
  resetToken: false, // serves to reset the token when it is used, as soon as the value is changed, regardless of the value of the property, the token is changed
});
const emit = defineEmits(["update:modelValue", "error", "unsupported"]);

const data = ref<IVueTurnstileData>({
  resetTimeout: undefined,
  widgetId: undefined,
});

const turnstile = ref();

const turnstileOptions = computed(() => {
  return {
    sitekey: props.siteKey,
    theme: props.theme,
    language: props.language,
    size: props.size,
    callback: callback,
    action: props.action,
    appearance: props.appearance,
    "error-callback": errorCallback,
    "unsupported-callback": unsupportedCallback,
  };
});

function unsupportedCallback() {
  emit("unsupported");
}

function errorCallback(code: string) {
  emit("error", code);
}

function callback(token: string) {
  emit("update:modelValue", token);
  startResetTimeout();
}

function reset() {
  if (window.turnstile) {
    emit("update:modelValue", "");
    window.turnstile.reset();
  }
}

function remove() {
  if (data.value.widgetId) {
    window.turnstile.remove(data.value.widgetId);
    data.value.widgetId = undefined;
  }
}

function render() {
  data.value.widgetId = window.turnstile.render(
    turnstile.value,
    turnstileOptions.value
  );
}

function startResetTimeout() {
  data.value.resetTimeout = setTimeout(() => {
    reset();
  }, props.resetInterval);
}
watch(
  () => props.resetToken,
  () => {
    // reset();
    render();
  }
);

onMounted(async () => {
  const turnstileLoadPromise = new Promise((resolve, reject) => {
    turnstileLoad = { resolve, reject };
    if (turnstileState === "ready") resolve();
  });

  window[turnstileLoadFunction] = () => {
    turnstileLoad.resolve();
    turnstileState = "ready";
  };

  const ensureTurnstile = () => {
    if (turnstileState === "unloaded") {
      turnstileState = "loading";
      const url = `${turnstileSrc}?onload=${turnstileLoadFunction}&render=explicit`;
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.addEventListener("load", function () {
        // on refresh page
        render();
      });
      script.addEventListener("error", () => {
        turnstileLoad.reject("Failed to load Turnstile.");
      });
      document.head.append(script);
    }
    return turnstileLoadPromise;
  };

  await ensureTurnstile();

  // on load page
  if (props.renderOnMount) {
    render();
  }
});
onBeforeUnmount(() => {
  remove();
  clearTimeout(data.value.resetTimeout);
});
</script>
