import api from "../api";
import { defineStore } from "pinia";

export const defaultStore = defineStore("Main", {
  state: () => ({
    counter: 0,
    isMobile: false,
    isBurger: false,
    invoiceForAction: null, // 
    invoiceAction: null
  }),
  getters: {
    doubleCounter() {
      return this.counter * 2;
    },
    getIsMobile() {
      return this.isMobile;
    },
    getInvoiceForAction() {
      return this.invoiceForAction;
    },
    getInvoiceAction() {
      return this.invoiceAction;
    }
  },
  actions: {
    incrementStore() {
      this.counter++;
      return this.counter;
    },
    setIsMobile(bool) {
      this.isMobile = bool;
    },
    setIsBurger(bool) {
      this.isBurger = bool;
    },
    setInvoiceForAction(id) {
      this.invoiceForAction = id;
    },
    setInvoiceAction(action) {
      this.invoiceAction = action
    }

  },
});
