import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import { createPinia } from "pinia";
const pinia = createPinia();
// import { VueReCaptcha } from "vue-recaptcha-v3";
import vClickOutside from "click-outside-vue3"



import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(i18n)
  .use(vClickOutside)
  .use(Toast);
  // .use(VueReCaptcha, { siteKey: "6LcGSP0kAAAAAA4PtVDtPHmjVaYVMCIvdk5L3U2W" })

router.isReady().then(() => app.mount("#app"));
