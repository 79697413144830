import router from "@/router";
import { useUserStore } from "@/store/userStore";
import { PAYMENT_METHODS, BAKERY_PAYMENT_METHODS } from "./types";

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const TWO_DECIMAL_REGEX = /^\d+(\.\d{1,2})?$/;
export const THREE_DECIMAL_REGEX = /^\d+(\.\d{1,3})?$/;

export function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  return EMAIL_REGEX.test(email);
}
export function getStorageEl(el) {
  return localStorage.getItem(`${el}`);
}
export function removeStorageEl(el) {
  return localStorage.removeItem(`${el}`);
}
export async function logout() {
  const userStore = useUserStore();
  removeStorageEl("sid");
  await userStore.logout();
}

export const getObjKeyWithValue = (obj, srcValue) => {
  return Object.keys(obj).find((key) => obj[key] === srcValue);
};
export const objectKeysToArr = (obj) => {
  return Object.keys(obj);
};

export const getPaymentMethods = function (is_bakery) {
  if (is_bakery) {
    return Object.values(BAKERY_PAYMENT_METHODS);
  }
  return Object.values(PAYMENT_METHODS);
};

export const filterNonNumeric = (str) => {
  return str.replace(/[^0-9]/g, "");
};
export const filterNonNumericaAndNonDot = (str) => {
  return str.replace(/[^0-9.]/g, "");
};
export const filterMaxChars = (str, max) => {
  return str.slice(0, max);
};
export function debounce(fn, wait = 500) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer); // clear any pre-existing timer
    }
    const context = this; // get the current context
    timer = setTimeout(() => {
      fn.apply(context, args); // call the function if time expires
    }, wait);
  };
}
export function typeObjectToMultiSelectArr(obj) {
  let res = [];
  Object.keys(obj).forEach((key) => res.push({ label: key, id: obj[key] }));
  return res;
}
export const validateTwoDecimal = (string) => {
  return TWO_DECIMAL_REGEX.test(string);
};
export const validateThreeDecimal = (string) => {
  return THREE_DECIMAL_REGEX.test(string);
};
