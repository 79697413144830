<template>
  <div class="page-verify">
    <div v-if="responseStatus === 200" class="wrap-verify">
      <h1>{{ t("app.auth.registerPage.verificationSuccess") }}</h1>
      <!-- <h1>status {{ responseStatus }}</h1> -->
      <router-link class="btn register ver-btn" to="/auth/login">
        {{ $t("app.auth.loginPage.title") }}
      </router-link>
    </div>
    <div
      v-else-if="responseStatus !== 200 && responseStatus !== 0"
      class="wrap-verify"
    >
      <h1>{{ t("app.auth.registerPage.verificationError") }}</h1>
      <router-link class="btn register ver-btn" to="/auth/register">
        {{ $t("app.auth.registerPage.title") }}
      </router-link>
      <TurnStile
        v-model="token"
        :resetToken="resetToken"
        action="product"
      ></TurnStile>
      <!-- <h1>status {{ responseStatus }}</h1> -->
    </div>
  </div>
</template>

<script setup>
// import { useReCaptcha } from "vue-recaptcha-v3";
import api from "../api/index";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const route = useRoute();
// const recaptcha = useReCaptcha();

const token = ref();
const resetToken = ref(false);
let verifToken = ref();
let responseStatus = ref(0);

const verifyEmail = async () => {
  // await recaptcha?.recaptchaLoaded();
  // let recaptchaToken = await recaptcha.executeRecaptcha("verify");
  const params = {
    token: verifToken.value,
    google_token: token.value,
  };
  resetToken.value = !resetToken.value;
  try {
    const res = await api.verifyAccountApi(params);
    responseStatus.value = res.status;
  } catch (error) {
    console.error(error);
    responseStatus.value = error.response.status;
  }
};

onMounted(() => {
  verifToken.value = route.query.token;
  verifyEmail();
});
</script>
<style lang="scss" scoped>
.page-verify {
  background: url("../assets/images/auth-bg.png") no-repeat center / cover;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .wrap-verify {
    margin: auto;
    // width: 50%;
    min-width: 300px;
    height: fit-content;
    padding: 60px 80px;
    background: rgba(255, 255, 255, 0.902);
    border: 1px solid var(--active-color);
    filter: drop-shadow(0px 3px 5px var(--active-color));
    border-radius: 10px;
    text-align: center;
    .ver-btn {
      margin: auto;
      margin-top: 20px;
      width: 200px;
      text-decoration: none;
      color: #fff;
    }
  }
}
</style>
