<template>
  <div class="wrapapiModal">
    <div class="wrappInput">
        <div class="label" >
            {{$t('app.api.textCheckDeleteKey') + 'id '+ props.options.key.apk_id }} ?
            </div>
        <div @click="deleteKey" class="btn keyButton">
            {{$t('app.api.delete')}}
        </div>

    </div> 
  </div>
</template>

<script setup>
import { useClientsStore } from "@/store/clientsStore";
import { ModalStore } from "@/store/modalStore";
import api from "@/api"

const clientsStore = useClientsStore();
const modalStore = ModalStore();

const props = defineProps(["options"]);


async function deleteKey(){
   
    if(!clientsStore.currentClient){
        return
    }

    let params= {
        cli_id: clientsStore.currentClient.cli_id,
        apk_id: props.options.key.apk_id
    }
    console.log(params)
    try {
        const res = await api.deleteKey(params)
        console.log(res.data)
        // if(res.data.res === ok){
        // }
        modalStore.setModalData(null);
        props.options.update();
    } catch (error) {
        console.log(error)
    } 
}



</script>

<style lang="scss" scoped>
.wrapapiModal{
     width: 400px;
}
.wrappInput{
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    .label{
        width: 150px;
        word-wrap: wrap;
        // white-space: nowrap;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 20px;
        /* identical to box height */


        color: #98A0B6;

    }
}


</style>