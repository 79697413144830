<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="pagination-wrap" v-if="props.pages">
    <span
      class="material-symbols-rounded"
      :class="{ alt: activePage === 1 }"
      @click="toFirst"
    >
      keyboard_double_arrow_left
    </span>
    <span
      class="material-symbols-rounded"
      :class="{ alt: activePage === 1 }"
      @click="decrease"
    >
      chevron_left
    </span>
    <div
      v-for="n in calcPages"
      :key="n"
      class="numbox"
      :class="{ active: activePage === n }"
      @click="setActive(n)"
    >
      {{ n }}
    </div>
    <span
      class="material-symbols-rounded"
      :class="{ alt: activePage === pages }"
      @click="increase"
    >
      chevron_right
    </span>
    <span
      class="material-symbols-rounded"
      :class="{ alt: activePage === pages }"
      @click="toLast"
    >
      keyboard_double_arrow_right
    </span>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";
const isMobile = inject("isMobile");

const setActive = (n) => {
  emit("setActive", n);
};

const increase = () => {
  if (props.activePage < props.pages) {
    setActive(props.activePage + 1);
  }
};

const decrease = () => {
  if (props.activePage > 1) {
    setActive(props.activePage - 1);
  }
};

const toFirst = () => {
  setActive(1);
};

const toLast = () => {
  if(props.pages)
    setActive(props.pages);
};

const calcPages = computed(() => {
  let pagesToRender = [];
if (props.activePage == 1 && props.pages < 3) {
  for(let i=0; i< props.pages; i++) {
    pagesToRender.push(i+1);
  }
  } else 
  if (props.activePage == 1 && props.pages >= 3) {
      pagesToRender = [1, 2, 3];
  } else if (props.activePage == props.pages) {
    pagesToRender = [props.pages - 2, props.pages - 1, props.pages];
  } else {
    pagesToRender = [
      props.activePage - 1,
      props.activePage,
      props.activePage + 1,
    ];
  }
  return pagesToRender;
});

const emit = defineEmits(["setActive", "increase", "decrease"]);
``;
const props = defineProps({
  activePage: {
    type: Number,
    required: false, // change to true
  },
  pages: {
    type: Number,
    required: false, // change to true
  },
});
</script>

<style lang="scss" scoped>
.pagination-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  .numbox {
    width: 35px;
    height: 35px;
    border-radius: 4px;
    background-color: $main-gray;
    margin: 0 8px;
    font-size: 14px;
    line-height: 17px;
    color: $main-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .dots {
    cursor: default;
    pointer-events: none;
    background-color: transparent;
    text-align: center;
  }

  .active {
    background-color: $main-green;
    color: #fff;
  }
  .material-symbols-rounded {
    color: $main-dark;
    margin: 0 8px;
    cursor: pointer;
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  }

  .alt {
    color: #acacad;
  }
}
</style>
