<template>
  <div class="forgot-password-wrapper">
    <h1>Resetujte lozinku</h1>
    <form @submit.prevent="submitForm" class="form" autocomplete="off">
      <!-- <label for="oldPassword">Stara lozinka:</label> -->
      <!-- <input v-model="oldPassword" type="password" id="oldPassword" required /> -->
      <!-- <label for="newPassword">Nova lozinka:</label> -->
      <!-- <input v-model="newPassword" type="password" id="newPassword" required /> -->
      <BaseInput
        v-model="newPassword"
        :notification="newPasswordNotify"
        class="mtb-20"
        :label="$t(`app.main.newPass`)"
        password
        autocomplete="false"
        @input="newPasswordNotify = null"
      />

      <BaseInput
        v-model="oldPassword"
        :notification="repeatPasswordNotify"
        class="mtb-20"
        :label="$t(`app.main.repeatPass`)"
        password
        autocomplete="false"
        @input="repeatPasswordNotify = null"
      />
      <TurnStile
        v-model="token"
        :resetToken="resetToken"
        action="reset-password"
      ></TurnStile>
      <button type="submit" class="submit-button">Potvrdi</button>
      <div :class="{ shakeWarn: isErrorNotifShaking }" class="errmsg">
        {{ errorNotif }}
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref } from "vue";
// import { useReCaptcha } from "vue-recaptcha-v3";
import { useI18n } from "vue-i18n";
import api from "@/api/index";
import TurnStile from "@/components/TurnStile.vue";

// const recaptcha = useReCaptcha();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();

console.log(router.currentRoute.value.query.token);
console.log(route.query.token);

const oldPassword = ref("");
const newPassword = ref("");
const token = ref();
const resetToken = ref(false);

// err
const repeatPasswordNotify = ref(null);
const newPasswordNotify = ref(null);
let errorNotif = ref(null);
let isErrorNotifShaking = ref(false);

// function setCaptchaToken(e) {
//   token.value = e;
// }
async function submitForm() {
  console.log("Stara lozinka:", oldPassword.value);
  console.log("Nova lozinka:", newPassword.value);

  errorNotif.value = null;

  //NEW PASSWORD VALIDATION
  if (!/[A-Z]/.test(newPassword.value)) {
    newPasswordNotify.value = t(
      "app.auth.registerPage.form.passwordUpperCaseMissing"
    );
    return;
  }
  if (!/[a-z]/.test(newPassword.value)) {
    newPasswordNotify.value = t(
      "app.auth.registerPage.form.passwordLowerCaseMissing"
    );
    return;
  }
  if (!/[0-9]/.test(newPassword.value)) {
    newPasswordNotify.value = t(
      "app.auth.registerPage.form.passwordNumberCaseMissing"
    );
    return;
  }
  if (newPassword.value.length > 32 || newPassword.value.length < 8) {
    newPasswordNotify.value = t("app.auth.registerPage.form.passwordLength");
    return;
  }
  if (!newPassword.value) {
    newPasswordNotify.value = t("app.auth.registerPage.form.passwordRequired");
    return;
  }

  if (!oldPassword.value || oldPassword.value.length <= 0) {
    repeatPasswordNotify.value = t("app.create-invoice.requiredField");
    return;
  } else if (oldPassword.value !== newPassword.value) {
    repeatPasswordNotify.value = t("app.main.notEqual");
    return;
  }

  try {
    //await recaptcha?.recaptchaLoaded();
    let recaptchaToken = token.value; //await recaptcha.executeRecaptcha("verify");
    /* const pass = route.query.token */
    const pass = router.currentRoute.value.query.token;
    let params = {
      password_token: pass,
      password: newPassword.value,
      token: recaptchaToken,
    };
    resetToken.value = !resetToken.value;
    const res = await api.resetPass(params);
    console.log(res);
    router.push("/auth/login");
  } catch (e) {
    console.log(e);
    if (e.response.data.message === "invalid-token") {
      errorNotif.value = t("app.main.backMessage.invalid-token");
    } else if (e.response.data.message === "token-expired") {
      errorNotif.value = t("app.main.backMessage.token-expired");
    } else {
      errorNotif.value = t("app.main.error");
    }
  }
}

const handleShake = () => {
  isErrorNotifShaking.value = true;
  setTimeout(() => {
    isErrorNotifShaking.value = false;
  }, 1500);
};

watch(errorNotif, () => {
  handleShake();
});
</script>

<style lang="scss" scoped>
.forgot-password-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100vh;
}

h1 {
  color: #344368;
  margin-bottom: 20px;
}

.form {
  max-width: 450px;
  width: 100%;
  padding: 20px;
  background-color: white;
  border: 1px solid #dddfe4;
  border-radius: 4px;
}

label {
  display: block;
  margin-bottom: 10px;
  color: #344368;
}

input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #dddfe4;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

input[type="password"]:hover,
input[type="password"]:focus {
  border-color: #2b8e85;
}

.submit-button {
  background-color: #39aca5;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #2b8e85;
}

.submit-button:active {
  background-color: #1f665d;
}
.errmsg {
  height: 30px;
  margin-top: 30px;
  color: red;
}
</style>
