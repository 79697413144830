<template>
    <div class="wrapapiModal">
      <div class="wrappInput">
          <!-- <div class="label">{{$t('app.api.emailForInvoice')}}</div> -->
          <BaseInput
             
              class="wide-input"
              :label="$t('app.auth.registerPage.emailLabel')"
              v-model="email"
          />
      </div>
      <div class="wrappInput">
          <div class="label" style="color:red">{{msg !== '' ? $t(msg): msg}}</div>
          <div @click="addKey" class="btn keyButton">
              {{$t('app.api.confirm')}}
          </div>
  
      </div> 
    </div>
  </template>
  
  <script setup>
  import { useClientsStore } from "@/store/clientsStore";
  import { ModalStore } from "@/store/modalStore";
  import { validateEmail } from "@/assets/js/helpers";
  import api from "@/api";
  
  const clientsStore = useClientsStore();
  const modalStore = ModalStore();
  const certificates = ref([]);
  const email = ref(null);
  const msg = ref('');
  const props = defineProps(["options"]);
  


  async function addKey(){
       msg.value ='';
      if(!clientsStore.currentClient)
          return;
      if(!email.value){
          msg.value = 'app.auth.registerPage.form.emailRequired';
      return;
      }
      if (!validateEmail(email.value)) {
        msg.value =  "app.auth.loginPage.form.emailInvalid";
          return;
      }
  
      let params= {
          email:email.value,
      };
      console.log(params);
        modalStore.setModalData(null);
        props.options.update(email.value);
    //   try {
    //       const res = await api.setKey(params)
    //       console.log(res.data)
    //       // if(res.data.res === ok){
    //       // }
    //       modalStore.setModalData(null);
    //       props.options.update();
    //   } catch (error) {
    //       console.log(error)
    //   } 
  }
  
  async function getCertificates() {
     if(!clientsStore.currentClient)
    return
   
    const cli_id = clientsStore.currentClient.cli_id;
    const params = {
      cli_id: cli_id,
    };
    try {
      const res = await api.getCertificates(params);
      certificates.value = res.data.data;
      console.log(certificates.value)
    } catch (error) {}
  }
  getCertificates()
  
  
  
  </script>
  
  <style lang="scss" scoped>
  .wrapapiModal{
       width: 400px;
  }
  .wrappInput{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label{
          width: 150px;
          white-space: nowrap;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          /* identical to box height */
  
  
          color: #98A0B6;
  
      }
  }
  
  
  </style>