<template>
  <div class="auth-wrap">
    <div class="main-view">
      <div class="header">
        <div class="logo gap">e<span>Fiskalko</span></div>
        <div class="version">
          {{ $t("app.ver") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          <h2>{{ $t("app.auth.forgorPwPage.title") }}</h2>
        </div>
        <div class="content-subtitle">
          <p>{{ $t("app.auth.forgorPwPage.subtitle") }}</p>
        </div>
        <div class="inputs-wrap">
          <BaseInput
            v-model="email"
            :notification="emailNotif"
            class="mtb-20"
            :label="$t(`app.auth.forgorPwPage.emailLabel`)"
            @input="emailNotif = null"
          />
        </div>
        <button @click="sendEmail" class="btn login">
          {{ $t("app.auth.forgorPwPage.linkBtn") }}
        </button>
        <div class="forgor-password">
          <!-- <a href="/auth/register">{{ $t("app.auth.forgorPwPage.backBtn") }}</a> -->
          <router-link to="register">
            {{ $t("app.auth.forgorPwPage.backBtn") }}
          </router-link>
        </div>
      </div>
      <div class="copyright">
        {{ $t("app.copyrights", { year: new Date().getFullYear() }) }}
      </div>
    </div>
    <div class="info-bar">
      <div class="info-text-wrap">
        <div class="info-title">
          <h2>{{ $t("app.auth.forgorPwPage.sidebarTitle") }}</h2>
        </div>
        <div class="info-text">
          {{ $t("app.main.registerTxt") }}
        </div>
        <TurnStile
          v-model="token"
          :resetToken="resetToken"
          action="forgot-page"
        ></TurnStile>
        <button class="btn white" @click="goToLogin">
          {{ $t("app.auth.forgorPwPage.sidebarBtn") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { validateEmail } from "@/assets/js/helpers";
import api from "@/api/index";
import { useI18n } from "vue-i18n";
import { ModalStore } from "@/store/modalStore";
// import { useReCaptcha } from "vue-recaptcha-v3";

// const recaptcha = useReCaptcha();

const router = useRouter();
const { t } = useI18n();
const modalStore = ModalStore();

const email = ref(null);
const emailNotif = ref(null);
const token = ref();
const resetToken = ref(false);
const goToLogin = () => {
  router.push("/auth/login");
};

async function sendEmail() {
  if (!validateEmail(email.value)) {
    emailNotif.value = t("app.auth.loginPage.form.emailInvalid");
    return;
  }
  try {
    // await recaptcha?.recaptchaLoaded();
    // let recaptchaToken = await recaptcha.executeRecaptcha("verify");

    let params = {
      email: email.value,
      token: token.value,
    };
    resetToken.value = !resetToken.value;
    const res = await api.getTokenResetPass(params);
    console.log(res);
    openModal();
  } catch (e) {
    console.log(e);
  }
}

function openModal() {
  const data = {
    component: "confirmModal",
    title: "app.main.titleForgotPass",
    options: {
      confirm: () => {
        modalStore.setModalData(null);
        email.value = null;
      },
      btnName: "app.main.close",
      text: "app.main.txtForgotPass",
    },
  };

  modalStore.setModalData(data);
}

console.log("forgot page");
</script>

<style lang="scss" scoped>
.gap {
  margin-right: 28px;
}
.content-subtitle {
  width: 546px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: $main-dark;
  margin-top: 0;
  margin-bottom: 10px;

  margin-top: 40px;
  font-weight: 500;
}
.forgor-password {
  width: 546px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  a {
    color: $main-green;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-decoration: none;
  }
}
.white {
  width: 500px;
  height: 50px;
  background-color: #fff;
  color: $main-dark;
  width: 260px;
}

@media only screen and (max-width: 800px) {
  .content-subtitle {
    width: 90%;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
  .forgor-password {
    width: 100%;
    a {
      font-size: 12px;
      line-height: 15px;
    }
  }
  .white {
    width: 162px;
    height: 40px;
  }
}
</style>
