<template>
<div class="component-wrap apiKey">
     <div class="content-header">
      <div class="title">
        <h2>{{ $t("app.sidebar.apiKey") }}</h2>
      </div>
      <div 
      class="info"
      title="Na ovoj stranici možete generisati API ključ
koji će vam omogućiti pristup našem servisu putem API-ja.
Prilikom generisanja API ključa morate odabrati
sertifikat sa liste koji će biti vezan za taj ključ.
Napomena: Korisnici koji koriste servis samo preko veb stranice 
i ne planiraju korištenje API-ja ne moraju praviti API ključeve."
      >
        i
      </div>
    </div>
    <div class="list-view">
        <div v-if="keyList.length > 0" class="list-wrapp">
            <div class="table">
                <div class="tableRow" >
                    <div class="td th">{{$t('app.api.numKey')}}</div>
                    <div class="td th date">{{$t('app.api.date')}}</div>
                    <div class="td th"></div>
                    <div class="td th delete"></div>
                </div>
                <div class="tableRow" v-for="(key, index) in keyList" :key="index">
                    <div class="td">
                        
                        <div v-if="showKey === index" class="input-copy">
                            <input v-model="key.apk_key" id="textForCopy" readonly="readonly">
                            <div class="copy" @click="copyApiKey"><img src="../../assets/images/icons/copyImg.svg" alt=""></div>
                        </div>
                        <div v-else class="star">*** *** *** ***</div>

                    </div>
                    <div  class="td date">{{formatDate(key.apk_created)}}.</div>
                    <div class="td show-hide">
                        <div v-if="showKey !== index" @click="showKey = index">{{$t('app.api.showKey')}}</div>
                        <div v-else @click="showKey = null">{{$t('app.api.hideKey')}}</div>
                    </div>
                    <div class="td delete" >
                        <img v-if="!key.apk_deleted"
                        @click="deleteModal(key)"
                         src="../../assets/images/delete.svg" alt="">
                    </div>
                </div>
               
            </div>
         
        </div>
        <div class="img-wrap">
            <!-- <img src="../../assets/images/apiKey.svg" alt="" /> -->
            <div class="img" >
                <div class="add_text">{{$t('app.api.addText')}}</div>
                <div  class="btn keyButton" @click="openModal">
                {{ $t("app.api.add") }}
                </div>
            </div>
        </div>
    </div>
     <div v-if="false && keyList.length > 0" class="content-footer">
      <div class="pagination">
        <Pagination
          :activePage="page"
          :pages="pagesCount"
          @setActive="changePage"
        />
      </div>
      <div class="total-results">
        {{
          $t("app.invoice-overview.results", {
            current: `${currentPagesStart} - ${currentPagesEnd}`,
            total: keyCount,
          })
        }}
      </div>
    </div>
</div>
  
</template>

<script setup>
// import api from "@/api/index.js";
import dayjs from "dayjs";
import api from "@/api"
import { useClientsStore } from "@/store/clientsStore";
import { ModalStore } from "@/store/modalStore";

const modalStore = ModalStore();
 
const clientsStore = useClientsStore();
const keyList = ref([])
let keyCount = ref(1); 
const showKey = ref(false)

//PAGINATION
let pagCount = ref(10);
let page = ref(1);
let searchByKey = ref("");



async function getApiKey(){
    if(!clientsStore.currentClient)
  return
     let params = {
      cli_id: clientsStore.currentClient.cli_id,
      count: pagCount.value,
      offset: (page.value - 1) * pagCount.value,
      search_by_key: searchByKey.value,
    };
    try {
        
    let res = await api.getKey(params);
    if (res.status === 200) {
      keyList.value = res.data.data;
      keyCount.value = res.data.meta_info.count;
    }
    } catch (error) {
        
    }
}
getApiKey()

const pagesCount = computed(() => {
  return Math.round(keyCount.value / pagCount.value);
});
const currentPagesStart = computed(() => {
  return page.value * pagCount.value - (pagCount.value - 1);
});
const currentPagesEnd = computed(() => {
  if (pagesCount.value === page.value) {
    console.log(keyCount.value);
    return keyCount.value;
  } else {
    return page.value * pagCount.value;
  }
});

function formatDate(date) {
  return dayjs(date).format("DD.MM.YYYY");
}

function copyApiKey() {
  // Get the text field
  var copyText = document.getElementById("textForCopy");

  // Select the text field
  copyText.select();
  copyText.setSelectionRange(0, 99999); // For mobile devices

   // Copy the text inside the text field
  navigator.clipboard.writeText(copyText.value);

//   // Alert the copied text
//   alert("Copied the text: " + copyText.value);
}


//CREATE API KEY
function openModal() {
  const data = {
    component: "addApiKey",
    title: "app.api.generateApi",
    options: {
      update: () => {
        getApiKey();
      },
    },
  };
  modalStore.setModalData(data);
}

function deleteModal(e) {
  const data = {
    component: "deleteApiKey",
    title: "app.api.textCheckDeleteKey",
    options: {
      update: () => {
        getApiKey();
      },
      key: e
    },
  };
  modalStore.setModalData(data);
}

</script>

<style lang="scss" scoped>
.apiKey{
    width:100%;
    .info{
      display: flex;
      justify-content: center;
      align-items: center;
      width:40px;
      height: 40px;
      font-size:30px;
      font-weight: bold;
      color:$main-green;
      border: 3px solid $main-green;
      border-radius: 20px;
      cursor: pointer;

    }
    .list-view{
        display: flex;
        .list-wrapp{
            width: 60%;
            .table{
                margin-top: 30px;
                width:100%;
                .tableRow {
                    display:flex;
                    justify-content: space-between;
                    border-radius: 30px;
                    overflow: hidden;
                    padding: 19px 41px;
                    
                    .td{
                       box-sizing: border-box;
                       padding: 0 5px;
                       width:100%;
                       border: none;
                       font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        /* identical to box height */

                        .star{
                            padding-top: 3px;
                        }
                        
                        .input-copy{
                            box-sizing: border-box;
                            width:100%;
                            position:relative;
                            display: flex;

                            input{
                                width:calc(100% - 38px);
                                outline: none;
                                border: none;
                                background: transparent;
                                padding-right: 5px;
                            }

                            .copy{
                                // position:absolute;
                                // right:5px;
                                // top:0;
                                cursor: pointer;
                            }

                        }

                    }
                    .td.date{
                        max-width:150px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 18px;
                        /* identical to box height */
                        color: #3B4161;

                    }
                    .th,
                    .th.date{
                        font-style: normal;
                        font-weight: 700;
                        font-size: 11px;
                        line-height: 13px;
                        text-transform: uppercase;

                        color: #ACACAD;
                    }
                    .td.show-hide{
                        font-weight: 700;
                        color:$main-green;
                        cursor: pointer;
                    }
                    .td.delete{
                            max-width: 20px;
                            cursor: pointer;

                    }
                }
                .tableRow:first-of-type{
                   padding: 10px 41px;  
                }
                .tableRow:nth-of-type(even){
                   background: $main-gray;
                }

            }
        }

        .img-wrap{
            width:40%;
            min-height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .img{
                width: 90%;
                aspect-ratio: 1 / 1;
                background-image: url("../../assets/images/apiKey.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                
                .add_text {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 25px;
                    /* identical to box height, or 156% */

                    text-align: center;

                    color: #344368;
                    margin-bottom: 20px;;
                }
                .keyButton{
                    width:100px;
                }

            }
        }
    }
    

}

@media only screen and (max-width: 1100px) {
    .apiKey{
        .list-view{
            flex-direction: column;
            align-items: center;

            .list-wrapp{
                width:100%;
            }
            .img-wrap{
                width:70%;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .apiKey{
        .list-view{
            flex-direction: column;
            align-items: center;

            .list-wrapp{
                width:100%;

                .table{
                    .tableRow{
                         padding: 19px 18px;

                        .td{
                            font-size: 13px;
                            line-height: 16px;

                        }
                         .td.date{
                            font-size: 15px;
                            line-height: 18px;
                         }
                        .th,
                        .th.date{
                            font-size: 11px;
                            line-height: 13px;
                        }
                    }
                    .tableRow:first-of-type{
                        padding: 10px 18px;  
                    }
                }
            }
            .img-wrap{
                width:100%;

                .img{
                    width: 100%;

                    .add_text {
                        font-size: 14px;
                        line-height: 24px;
                    }
                    .keyButton{
                        font-size: 14px;
                        width: 90px;
                        height: 35px;
                    }
                }
            }
        }
    }
}
</style>