export const INVOICE_TYPES = {
  NORMAL: 0,
  PROFORMA: 1,
  COPY: 2,
  TRAINING: 3,
  ADVANCE: 4,
};
export const INVOICE_TYPES_NEW = [
  { label: "NORMAL", id: 0 },
  { label: "PROFORMA", id: 1 },
  { label: "COPY", id: 2 },
  { label: "TRAINING", id: 3 },
  { label: "ADVANCE", id: 4 },
];

export const INVOICE_STATUS = {
  PENDING: 0,
  SUCCESS: 1,
  FAIL: 2,
  REFUND: 3,
};

export const TAX_TYPES = {
  ON_NET: 0,
  ON_TOTAL: 1,
  PER_QUANTITY: 2,
};

export const BAKERY_PAYMENT_METHODS = {
  OTHER: 0,
  CASH: 1,
  WIRE_TRANSFER: 4,
  VOUCHER: 5,
};

export const PAYMENT_METHODS = {
  OTHER: 0,
  CASH: 1,
  CARD: 2,
  CHECK: 3,
  WIRE_TRANSFER: 4,
  VOUCHER: 5,
  MOBILE_MONEY: 6,
};
// export const PAYMENT_METHODS_NEW = [
//   { label: 'OTHER', id: 0 },
//   { label: 'CASH', id: 1 },
//   { label: 'CARD', id: 2 },
//   { label: 'CHECK', id: 3 },
//   { label: 'WIRE_TRANSFER', id: 4 },
//   { label: 'VOUCHER', id: 5 },
//   { label: 'MOBILE_MONEY', id: 6 },
// ];

export const PAYMENT_METHODS_NEW = [
  { label: 'Drugo bezgotovinsko placanje', id: 0 },
  { label: 'Gotovina', id: 1 },
  { label: 'Platna kartica', id: 2 },
  { label: 'Čekovi građana', id: 3 },
  { label: 'Prenos na račun', id: 4 },
  { label: 'Bezgotovinski vaučer', id: 5 },
  { label: 'Instant plaćanje', id: 6 },
];

export const RESTRICT_FOR_BACKERY = [2, 3, 6];

export const TRANSACTION_TYPE = {
  SALE: 0,
  REFUND: 1,
};
export const TRANSACTION_TYPE_NEW = [
  { id: 0, label: "SALE" },
  { id: 1, label: "REFUND" }
];

export const SPECIAL_ARTICLE_TYPES = {
  ADVANCE: 1,
  VOUCHER: 2,
};
