import { defineStore } from "pinia";
import api from "@/api";
import router from "@/router";
import { getStorageEl } from "@/assets/js/helpers";
export const useUserStore = defineStore("Users", () => {
  const sid = ref(null);
  const id = ref(null);
  const name = ref(null);
  const isLoggedIn = ref(false);

  function $reset() {
    sid.value = null;
    id.value = null;
    name.value = null;
    isLoggedIn.value = false;
  }

  async function checkSession() {
    // when the session breaks, the user should be sent to the login page.. 
    // but that is impossible because then the function calls are started endlessly..
    // because this function is called in the router - that's why I wrote a new one to check the session.
    try {
      let res = await api.checkSession();
      if (res.status === 200) {
        sid.value = getStorageEl("sid");
        id.value = res.data.id;
        name.value = res.data.name;
        isLoggedIn.value = true;
      }
    } catch (error) {
      $reset();
    }
  }
  async function logout() {
    try {
      await api.logoutApi();
      $reset();
    } catch (error) {
      console.error(error);
    } finally {
      router.push({ name: "LoginPage" });
    }
  }
  return {
    sid,
    id,
    name,
    isLoggedIn,
    checkSession,
    $reset,
    logout,
  };
});
