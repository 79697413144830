<!-- <template lang="pug">
div Page not found!
</template>

<script setup></script>

<style lang="scss" scoped>
div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style> -->
<template>
  <div class="home-wrap">
    <Transition name="fade">
      <PopupMenu
        v-if="isExpanded"
        @closeBurgir="closeBurgir"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
      />
    </Transition>
    <HeaderComp @openBurgir="openBurgir" />
    <div class="content-wrap">
      <Sidebar v-if="true" />
      <div
        class="content-component"
        :style="[isBurger || isMobile ? 'margin-left: 0' : '']"
      >
        <!-- <RouterView v-slot="{ Component }">
          <Transition name="router-fade" mode="out-in">
            <component :is="Component" />
          </Transition>
        </RouterView> -->
        <div class="not-found-content">
          <div class="page-not-found-message-btn">
            <p>Stranica nije pronadjena</p>
            <button class="btn">Nazad</button>
          </div>
          <div class="not-found-image">
            <img
              src="../assets/images/page-not-found.png"
              alt="page not found image"
              srcset=""
            />
          </div>
        </div>
      </div>
    </div>
    <FooterComp />
  </div>
</template>
<script setup>
import { RouterView } from "vue-router";

let isBurger = inject("isBurger");
let isMobile = inject("isMobile");
let isExpanded = ref(false);

const openBurgir = () => {
  isExpanded.value = true;
};
const closeBurgir = () => {
  isExpanded.value = false;
};
</script>

<style lang="scss" scoped>
.home-wrap {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .content-wrap {
    width: 100%;
    min-height: 100vh;
    padding: 38px 90px 38px 100px;
    display: flex;

    .content-component {
      width: 100%;
      height: 100%;
      margin-left: 60px;
      .not-found-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .page-not-found-message-btn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            font-family: "Lato";
            font-style: normal;
            font-weight: 800;
            font-size: 30px;
            line-height: 48px;
            color: #344368;
          }
          .btn {
            width: 150.22px;
            margin: 10px 0 0 0;
          }
        }
        .not-found-image {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1490px) {
  .home-wrap {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .content-wrap {
      width: 100%;
      min-height: 100vh;
      padding: 38px 90px 38px 100px;
      display: flex;

      .content-component {
        width: 100%;
        height: 100%;
        margin-left: 60px;
        .not-found-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .page-not-found-message-btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p {
              font-family: "Lato";
              font-style: normal;
              font-weight: 800;
              font-size: 24px;
              line-height: 48px;
              color: #344368;
            }
            .btn {
              width: 150.22px;
              margin: 10px 0 20px 0;
            }
          }
          .not-found-image {
            display: flex;
            justify-content: center;
            img {
              width: 60%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .home-wrap {
    .content-wrap {
      // padding: 40px 27px 40px 27px;
      padding: 30px 17px 30px 21px;
      .content-component {
        margin-left: 0;
      }
    }
  }
}
</style>
