<template>
  <div class="wrapapiModal">
    <div class="wrappInput">
        <div class="label">{{$t('app.api.sertifSelect')}}</div>
        <BaseSelect
            style="width:250px"
            class="wide-input"
            :showTopLabel="true"
            :placeholder="$t('app.api.sertifSelect')"
            :applyWrap="true"
            :topLabelText="''"
            :options="getCertifyOptions"
            :searchable="true"
            :taggable="true"
            :value="certify"
            @selected="select"
        />
    </div>
    <div class="wrappInput">
        <div class="label" style="color:red">{{msg !== '' ? $t(msg): msg}}</div>
        <div @click="addKey" class="btn keyButton">
            {{$t('app.api.generateApi')}}
        </div>

    </div> 
  </div>
</template>

<script setup>
import { useClientsStore } from "@/store/clientsStore";
import { ModalStore } from "@/store/modalStore";
import api from "@/api"

const clientsStore = useClientsStore();
const modalStore = ModalStore();
const certificates = ref([]);
const certify = ref(null)
const msg = ref('')
const props = defineProps(["options"]);

function select(e){
     msg.value =''
    console.log(e)
    certify.value = e
}
async function addKey(){
     msg.value =''
    if(!clientsStore.currentClient)
        return
    if(!certify.value){
        msg.value = 'app.api.selectSertify'
    return;
    }

    let params= {
        cli_id: clientsStore.currentClient.cli_id,
        crt_id: certify.value.id
    }
    console.log(params)
    try {
        const res = await api.setKey(params)
        console.log(res.data)
        // if(res.data.res === ok){
        // }
        modalStore.setModalData(null);
        props.options.update();
    } catch (error) {
        console.log(error)
    } 
}

async function getCertificates() {
   if(!clientsStore.currentClient)
  return
 
  const cli_id = clientsStore.currentClient.cli_id;
  const params = {
    cli_id: cli_id,
  };
  try {
    const res = await api.getCertificates(params);
    certificates.value = res.data.data;
    console.log(certificates.value)
  } catch (error) {}
}
getCertificates()

const getCertifyOptions = computed(() => {
  return certificates.value.map((el) => ({ label: el.crt_name, id: el.crt_id }));
});


</script>

<style lang="scss" scoped>
.wrapapiModal{
     width: 400px;
}
.wrappInput{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .label{
        width: 150px;
        white-space: nowrap;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        /* identical to box height */


        color: #98A0B6;

    }
}


</style>