<template>
  <div class="component-wrap">
    <div class="content-header">
      <div class="title">
        <h2>
          {{ $t("app.docs.title") }}
        </h2>
      </div>
    </div>
    <div class="content-bar">
      <SearchInput :placeholder="$t(`app.docs.searchLabel`)" />
      <button v-if="!isMobile" class="btn newBoi">
        {{ $t("app.docs.newCompBtn") }}
      </button>
      <button v-if="isMobile" class="plus btn newBoi">
        <span class="material-symbols-rounded"> add </span>
      </button>
    </div>
    <div class="page-content">
      <!-- <div class="single-certificate">
        <div class="item-image">
          <img src="../../assets/images/test-img.jpeg" alt="" />
        </div>
        <div class="item-name">Sertifikat 1</div>
      </div> -->
      <div class="item-list-wrap">
        <div class="item-list-header">
          <span v-if="!isMobile" class="row-1">{{
            $t("app.compnay.serialNumber").toUpperCase()
          }}</span>
          <span class="row-2">{{
            $t("app.compnay.companyName").toUpperCase()
          }}</span>
          <span class="row-3">{{
            $t("app.compnay.companyDesc").toUpperCase()
          }}</span>
          <span class="row-4">{{ $t("app.compnay.pib").toUpperCase() }}</span>
          <span class="row-5">{{
            $t("app.compnay.idNumber").toUpperCase()
          }}</span>
          <span class="row-6">{{
            $t("app.compnay.address").toUpperCase()
          }}</span>
        </div>
        <div
          class="item-list-item"
          v-for="n in 6"
          :key="n"
          :class="{ alt: n % 2 === 1 }"
        >
          <div v-if="!isMobile" class="item-num">{{ n }}</div>
          <div class="item-name">Ime {{ n }}</div>
          <div class="item-price">69.420,00</div>
          <div class="item-gtin">123456789</div>
          <div class="item-gtin">123456789</div>
          <div class="item-gtin">123456789</div>
          <div class="btns">
            <div class="edit-btn" @click="editItem">
              <span class="material-symbols-rounded"> edit </span>
            </div>
            <div class="del-btn" @click="delItem">
              <span class="material-symbols-rounded"> delete </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
let isMobile = inject("isMobile");
</script>

<style lang="scss" scoped>
.content-bar {
  .newBoi {
    width: 150px;
    height: 38px;
    margin-left: $spacing-margine;
  }
  .plus {
    width: $circle-btn-mobile-size;
  }
  justify-content: space-between;
}
.page-content {
  .item-list-wrap {
    max-width: 100%;
    width: 100%;
    margin-bottom: 25px;
    .item-list-header {
      padding: 0 33px;
      display: flex;
      .row-1 {
        width: 60px;
      }
      .row-2 {
        width: 205px;
      }
      .row-3 {
        width: 154px;
      }
      .row-4 {
        width: 136px;
      }
      .row-5 {
        width: 174px;
      }
      .row-6 {
        width: 156px;
      }

      span {
        text-align: left;
        color: #acacad;
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
      }
    }
    .item-list-item {
      width: 100%;
      height: 50px;
      padding: 0 33px;
      border-radius: 50px;

      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      .item-num {
        width: 50px;
      }
      .item-name {
        width: 216px;
      }
      .item-price {
        width: 154px;
      }
      .item-gtin {
        width: 156px;
      }

      div {
        text-align: left;
        color: #3b4161;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
      }
      .item-num,
      .item-name {
        font-weight: 700;
      }
      .btns {
        margin-left: 122px;
        width: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .alt {
      background-color: #f6f6f6;
    }
  }
}

.material-symbols-rounded {
  font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
}

@media only screen and (max-width: 800px) {
  .newBoi {
    width: 38px;
  }

  .material-symbols-rounded {
    font-size: 30px;
    line-height: 38px;
  }
}
</style>
