<template>
  <div class="view-invoice-wrapper">
    <div class="everything-wrapper-cuz-i-forgor-smt" v-if="company">
      <div class="invoice-header">
        <div class="left-side">
          <div class="invoice-status">
            {{ $t("app.sidebar.companies") }}
          </div>
        </div>
        <div class="right-side">
        </div>
      </div>
      <div  class="details-wrap">
        <div class="details-left">
          <div class="field alt">
            <div class="field-title">
              {{ $t("app.compnay.pib") }}
            </div>
            <div class="field-value" v-if="!isEditing && !isCreating">
              {{ company.cli_pib }}
            </div>
            <div class="editPib" v-if="isEditing || isCreating">
              <input
                type="text"
                v-model="company.cli_pib"
                class="custom-input"
              />
            </div>
          </div>
          <div class="field">
            <div class="field-title">
              {{ $t("app.compnay.companyName") }}
            </div>
            <div class="field-value" v-if="!isEditing && !isCreating">
              {{ company.cli_name }}
            </div>
            <div class="editCompanyName" v-if="isEditing || isCreating">
              <input
                type="text"
                v-model="company.cli_name"
                class="custom-input"
              />
            </div>
          </div>
          <div class="field alt">
            <div class="field-title">
              {{ $t("app.compnay.companyContact") }}
            </div>
            <div class="field-value" v-if="!isEditing && !isCreating">
              {{ company.cli_email }}
            </div>
            <div class="editCompanyContact" v-if="isEditing || isCreating">
              <input
                type="text"
                v-model="company.cli_email"
                class="custom-input"
              />
            </div>
          </div>
          <div class="field">
            <div class="field-title">
              {{ $t("app.compnay.companyCity") }}
            </div>
            <div class="field-value" v-if="!isEditing && !isCreating">
              {{ company.cli_city }}
            </div>
            <div class="editCompanyCity" v-if="isEditing || isCreating">
              <input
                type="text"
                v-model="company.cli_city"
                class="custom-input"
              />
            </div>
          </div>
          <div class="field alt">
            <div class="field-title">
              {{ $t("app.compnay.companyTownship") }}
            </div>
            <div class="field-value" v-if="!isEditing && !isCreating">
              {{ company.cli_region }}
            </div>
            <div class="editCompanyTownship" v-if="isEditing || isCreating">
              <input
                type="text"
                v-model="company.cli_region"
                class="custom-input"
              />
            </div>
          </div>
          <div class="field">
            <div class="field-title">
              {{ $t("app.compnay.companyStreetNumber") }}
            </div>
            <div class="field-value" v-if="!isEditing && !isCreating">
              {{ company.cli_address }}
            </div>
            <div class="editCompanyStreetNumber" v-if="isEditing || isCreating">
              <input
                type="text"
                v-model="company.cli_address"
                class="custom-input"
              />
            </div>
          </div>
          <div class="field alt">
            <div class="field-title">
              {{ $t("app.compnay.companyPostNumber") }}
            </div>
            <div class="field-value" v-if="!isEditing && !isCreating">
              {{ company.cli_postal_code }}
            </div>
            <div class="editCompanyPostNumber" v-if="isEditing || isCreating">
              <input
                type="text"
                v-model="company.cli_postal_code"
                class="custom-input"
              />
            </div>
          </div>
          <div class="field">
            <div class="field-title">
              {{ $t("app.compnay.companyPhoneNumber") }}
            </div>
            <div class="field-value" v-if="!isEditing && !isCreating">
              {{ company.cli_phone_number }}
            </div>
            <div class="editCompanyPhoneNumber" v-if="isEditing || isCreating">
              <input
                type="text"
                v-model="company.cli_phone_number"
                class="custom-input"
              />
            </div>
          </div>
          <div class="field alt">
            <div class="field-title">
              {{ $t("app.compnay.companyActivity") }}
            </div>
            <div class="field-value" v-if="!isEditing && !isCreating">
              {{ company.cli_activity }}
            </div>
            <div class="editCompanyActivity" v-if="isEditing || isCreating">
              <input
                type="text"
                v-model="company.cli_activity"
                class="custom-input"
              />
            </div>
          </div>
          <div class="field">
            <div class="field-title">
              {{ $t("app.compnay.idNumber") }}
            </div>
            <div class="field-value" v-if="!isEditing && !isCreating">
              {{ company.cli_mb }}
            </div>
            <div class="editCompanyIdNumber" v-if="isEditing || isCreating">
              <input
                type="text"
                v-model="company.cli_mb"
                class="custom-input"
              />
            </div>
          </div>
          <div 
           v-if="isEditing || isCreating" 
           class="field alt"
          >
          <label 
           for="isBakery"
           title="Pravilnik o vrstama fiskalnih računa, tipovima transakcija, načinima plaćanja, pozivanju na broj drugog dokumenta i pojedinostima ostalih elemenata fiskalnog računa, član 6, stav 2">
              <input v-model="company.cli_is_bakery" type="checkbox" name="isBakery" id="isBakery" >
              {{$t('app.main.isBackery')}} ?
          </label>
          </div>
          <!-- <div class="field">
            <div class="field-title">
              {{ $t("app.compnay.companyEmail") }}
            </div>
            <div class="field-value" v-if="!isEditing && !isCreating">
              {{ company.cli_email }}
            </div>
            <div class="editCompanyIdNumber" v-if="isEditing || isCreating">
              <input
                type="text"
                v-model="company.cli_email"
                class="custom-input"
              />
            </div>
          </div> -->
          <div class="edit">
            <div class="change-data">
              <button
                class="btn"
                v-if="isEditing"
                @click="turnOfEditCompanyMode"
              >
                {{ $t("app.compnay.cancelEdit") }}
              </button>
              <button
                class="btn"
                v-if="!isEditing && !isCreating"
                @click="turnOnEditCompanyMode"
              >
                {{ $t("app.compnay.changeBtn") }}
              </button>
              <button
                class="btn"
                v-if="!isEditing && isCreating"
                @click="cancelCreatingCompany"
              >
                {{ $t("app.compnay.cancelCreatingCompany") }}
              </button>
            </div>
            <div class="confirm-data">
              <button v-if="isEditing" class="btn" @click="changeCompany">
                {{ $t("app.compnay.confirmEdit") }}
              </button>
              <button v-if="isCreating" class="btn" @click="createCompany">
                {{ $t("app.compnay.confirmCreate") }}
              </button>
            </div>
          </div>
        </div>
        <div class="details-right">
          <div class="certificates" v-if="certificates">
            <div
              class="certificate"
              v-for="(certificate, i) in certificates"
              :class="i % 2 === 0 ? 'grey' : ''"
              :key="i"
            >
              <span
                class="cId"
                :title="
                  certificate.crt_default === 1
                    ? $t('app.compnay.defaultCertificate')
                    : ''
                "
              >
                <!-- {{ certificate.crt_id }} -->
                {{ certificate.crt_default === 1 ? "podrazumevani" : "" }}
              </span>
              <span class="cName"
                >{{ certificate.crt_name }} <br />
                {{ formattedCertificationDate(certificate.crt_created) }} </span
              ><span class="cOk"
                ><img src="../../assets/images/icons/ok.png" alt="" srcset=""
              /></span>
              <span class="cX">
                <img
                  v-if="certificate.crt_default === 0"
                  :title="$t('app.compnay.setDefaultCertificate')"
                  @click="setDefaultCertificate(certificate.crt_id)"
                  src="../../assets/images/icons/star.svg"
                  alt="*"
                />
                <img
                  v-if="certificate.crt_default === 0"
                  @click="delItem(certificate.crt_id, certificate.crt_name)"
                  src="../../assets/images/icons/trash.svg"
                  alt="x"
                />
              </span>
            </div>
            <div v-if="certificates.length <= 0 && !isCreating" class="info">
              Kada dobijete sertifikat u obliku fajla od Poreske uprave možete ga ovde dodati pritiskom na dugme "Dodaj" kako biste mogli da pravite račune.
          </div>
          </div>
          <div class="add-certificate" v-if="!isCreating">
            <div
              class="import-content"
              @click="simulateFileInput"
              @dragover.prevent
              @drop="onFileDrop"
            >
              <input
                ref="fileInputRef"
                type="file"
                style="display: none"
                @change="onFileChange"
                multiple
              />
              <span class="import-image">
                <img src="../../assets/images/import.png" alt="" srcset="" />
              </span>
              <span class="import-title">Dodajte vase sertifikate</span>
              <span class="import-button"
                ><button class="btn">Dodaj</button></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="wrappTextCreate">
      <h2>{{ $t("app.compnay.companyNotFound") }}</h2>
      <span class="import-button createCompany"
        ><button @click="creatingCompanyOn" class="btn">
          Napravi kompaniju
        </button></span
      >
    </div>
    <!-- <div v-if="false" class="side-img">
      <img src="../../assets/images/view-invoice.png" alt="" />
    </div> -->
  </div>
</template>

<script setup>
import api from "../../api/index";
import { useRoute } from "vue-router";
import { useClientsStore } from "@/store/clientsStore";
import dayjs from "dayjs";
import { useToast } from "vue-toastification";
import { ModalStore } from "@/store/modalStore";

const modalStore = ModalStore();

const toast = useToast();
const clientsStore = useClientsStore();
const route = useRoute();

const isEditing = ref(false);
const isCreating = ref(false);

const certificates = ref([]);
const company = ref(null);
const oldCompany = ref(null);

let addCertificateParams = ref({});

function openModal() {
  const data = {
    component: "ConfirmCreatingCertificates",
    title: "app.compnay.creatingCertificateTitle",
    modalData: files.value,
    options: {
      update: () => {
        getCertificates();
      },
    },
  };
  modalStore.setModalData(data);
}
async function createCompany() {
  const params = {
    // cli_id: clientsStore.currentClient.cli_id,
    cli_name: company.value.cli_name,
    cli_pib: company.value.cli_pib,
    cli_region: company.value.cli_region,
    cli_activity: company.value.cli_activity,
    cli_email: company.value.cli_email,
    cli_address: company.value.cli_address,
    cli_mb: company.value.cli_mb,
    // cli_is_bakery: company.value.cli_is_bakery,
    cli_city: company.value.cli_city,
    cli_phone_number: company.value.cli_phone_number,
    cli_postal_code: company.value.cli_postal_code,
    cli_is_bakery: (company.value.cli_is_bakery === 1 || company.value.cli_is_bakery === true)? 1 : 0
  };
  try {
    const res = await api.createCompany(params);
    toast.success("Uspesno ste napravili kompaniju.");
    await clientsStore.fetchClients();
    clearFild();
    isCreating.value = false;
    console.log(res);
  } catch (error) {
    toast.error("Popunite sva polja.");
    console.log(error);
  }
}
watch(
  ()=> clientsStore.currentClient,
  (newVal)=> {
     console.log('watch'+ newVal.cli_id)
     
     getCompany(newVal.cli_id);
  }
)

function creatingCompanyOn() {
  company.value = {
    cli_name: "",
    cli_pib: "",
    cli_region: "",
    cli_activity: "",
    cli_email: "",
    cli_address: "",
    cli_mb: "",
    cli_is_bakery: 0,
    cli_city: "",
    cli_phone_number: "",
    cli_postal_code: "",
  };
  isCreating.value = true;
}

function cancelCreatingCompany() {
  company.value = null;
}

const files = [];

async function onFileChange(e) {
  files.value = Array.from(e.target.files);
  if (files.value.length > 5) {
    toast.error("Možete odabrati najviše 5 sertifikata.");
    return;
  }
  console.log(files.value);
  openModal(files.value);
}

function delItem(id, name) {
 
  const data = {
    component: "confirmModal",
    title: "app.api.delete",
    options: {
      confirm: () => {
        deleteCertificate(id);
      },
      name: name,
    },
  };
  modalStore.setModalData(data);


}

async function deleteCertificate(id) {
  // console.log(clientsStore.currentClient.cli_id);
  // console.log(clientId.value);
  // console.log(clientId.value);
  const params = { 
      cli_id: clientsStore.currentClient.cli_id, 
      id: id 
    };
  try {
    const res = await api.deleteCertificate(params);
    // console.log(res);
    getCertificates();
  } catch (error) {
    console.log(error);
  }
}
async function setDefaultCertificate(id) {
  if (!clientsStore.currentClient) return;
  console.log(clientsStore.currentClient.cli_id);

  const params = {
    cli_id: clientsStore.currentClient.cli_id,
    id: id,
  };
  try {
    const res = await api.setDefaultCertificates(params);
    // console.log(res);
    getCertificates();
  } catch (error) {
    console.log(error);
  }
}

async function getCertificates() {
  if (!clientsStore.currentClient) return;
  const cli_id = clientsStore.currentClient.cli_id;
  const params = {
    cli_id: cli_id,
  };
  try {
    const res = await api.getCertificates(params);
    certificates.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}

const simulateFileInput = (e) => {
  fileInputRef.value.click();
};

// const simulateFileInput = () => {
//   fileInputRef.value.multiple = true;
//   fileInputRef.value.click();
// };

// const onFileDrop = (event) => {
//   event.preventDefault();
//   const files = event.dataTransfer.files;
//   if (files.length > 0) {
//     fileInputRef.value.files = files;
//   }
// };

const fileInputRef = ref(null);

function turnOnEditCompanyMode() {
  isEditing.value = true;
}
function turnOfEditCompanyMode() {
  getCompany();
  isEditing.value = false;
}
async function changeCompany() {
  if (!clientsStore.currentClient) return;
  console.log(oldCompany.value)
  try {
    const cli_id = clientsStore.currentClient.cli_id;
    /*
    let params = {
      cli_id: cli_id,
      cli_name: company.value.cli_name,
      cli_pib: company.value.cli_pib,
      cli_region: company.value.cli_region,
      cli_activity: company.value.cli_activity,
      cli_email: company.value.cli_email,
      cli_address: company.value.cli_address,
      cli_mb: company.value.cli_mb,
      // cli_is_bakery: company.value.cli_is_bakery,
      cli_city: company.value.cli_city,
      cli_phone_number: company.value.cli_phone_number,
      cli_is_bakery: (company.value.cli_is_bakery === 1 || company.value.cli_is_bakery === true)? 1 : 0,
      cli_postal_code: company.value.cli_postal_code,
    };
    */
   let params = checkParams();
    if(Object.keys(params).length === 0 ){
      toast.error("Nema promena.");
      return;
    }
    params.cli_id = cli_id;
    console.log(params);
    const res = await api.changeCompany(params);
    toast.success("Uspesno ste izmenili kompaniju.");
    isEditing.value = false;
    console.log(res);
  } catch (error) {
    console.log(error);
  }

  try {
     clientsStore.fetchClients();
  } catch (error) {
    console.log(error)
  }
}

function checkParams(){
  let params = {};
  for(let i in company.value){
    if(company.value[i] !== oldCompany.value[i]){
      if( i === "cli_is_bakery") {
        params[i] = (company.value.cli_is_bakery === 1 || company.value.cli_is_bakery === true)? 1 : 0;
      } else {
        params[i] = company.value[i];
      }
    }
  }
  return params;
}


async function getCompany(id) {
  //   const id = route.params.id;
  console.log(clientsStore.currentClient)
  if (!clientsStore.currentClient && !id) return;
  let params = {
    cli_id: id ? id :  clientsStore.currentClient?.cli_id,
  };
  try {
    let res = await api.getSingleCompany(params);
    if (res.status === 200) {
      company.value = res.data.data;
      company.value.cli_is_bakery = res.data.data.cli_is_bakery===1 ? true: false
      // new 
      oldCompany.value= { ...res.data.data };
    }
  } catch (error) {
    console.error(error);
  }
}

function formattedCertificationDate(date) {
  return dayjs(date).format("DD.MM.YYYY");
}

const clientId = computed(() => {
  return clientsStore.currentClient.cli_id;
});

function clearFild() {
    company.value.cli_name = "";
    company.value.cli_pib = "";
    company.value.cli_region = "";
    company.value.cli_activity = "";
    company.value.cli_email = "";
    company.value.cli_address = "";
    company.value.cli_mb = "";
    company.value.cli_is_bakery = "";
    company.value.cli_city = "";
    company.value.cli_phone_number = "";
    company.value.cli_postal_code = "";
}

onMounted(async () => {
  await getCompany();
  await getCertificates();
});
</script>

<style lang="scss" scoped>
.view-invoice-wrapper {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  margin-bottom: 30px;
  .everything-wrapper-cuz-i-forgor-smt {
    // width: 1100px;
    .invoice-header {
      width: 100%;
      margin-bottom: 30px;
      padding-left: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left-side {
        width: 380px;
        display: flex;
        justify-content: space-between;
        span {
          color: $main-green;
        }
      }
      .right-side {
        width: 320px;
        display: flex;
        justify-content: space-between;

        .pdf,
        .email {
          width: 150px;
          height: $circle-btn-mobile-size;
        }
      }
    }

    .details-wrap {
      max-height: 510px;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      .details-left {
        padding: 0 10px 0 0;
        .edit {
          display: flex;
          flex-direction: row;
          .change-data {
            margin: 30px 150px 20px 0;
          }
          .confirm-data {
            margin: 30px 150px 20px 0;
          }
          .btn {
            padding: 10px 21px 9px 21px;
          }
        }
      }
      .details-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        .add-certificate {
          width: 234px;
          height: 434px;
          background: #ffffff;
          border: 1px solid #dfdfe8;
          box-shadow: 0px 1px 3px #dfdfe8;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px 0 0 0;
          .import-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .import-image {
              cursor: pointer;
            }
            .import-title {
              margin: 27px 0 47px 0;
              cursor: pointer;
            }
            .import-title:hover {
              color: #2bb498;
            }
            .import-button {
              button {
                padding: 10px 53px 9px 54px;
              }
            }
          }
        }
        /* width */
        ::-webkit-scrollbar {
          width: 6px;
          // height: 90px;
          // transform: rotate(180deg);
        }

        /* Track */
        ::-webkit-scrollbar-track {
          // background: #f1f1f1;
          background: transparent;
          box-shadow: inset 0 0 15px #dddddd;
          border-radius: 4px;
          border-left: 2.5px solid transparent;
          border-right: 2.5px solid transparent;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #9c9c9c;
          border-radius: 10px;
          transform: scale(0.5);
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .certificates {
          height: 224px;
          overflow-x: hidden;
          overflow-y: auto;
          margin: 0 0 20px 0;
          .grey {
            background-color: $main-gray;
          }
          .certificate {
            border-radius: 50px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 534px;
            height: 56px;
            .cId {
              width: 20%;
              overflow: hidden;
              text-overflow: ellipsis;
              font-style: normal;
              font-weight: 700;
              font-size: 12px;
              line-height: 14px;
              color: #28b498;

              text-shadow: 0px 0px 4px rgba(40, 180, 152, 0.4);
            }
            .cName {
              width: 200px;
            }
            .cOk {
              width: 30px;
              cursor: pointer;
            }
            .cX {
              text-align: right;
              min-width: 30px;
              cursor: pointer;
              img {
                margin: 0 4px;
              }
            }
          }
          .info{
             width: 400px;
             font-weight: 600;
             font-size: 1.3em;
             color: var(--erorr-color);
            //  text-align: center;
          }
        }
      }
      .field {
        width: 512px;
        height: 56px;
        border-radius: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        &.alt {
          background-color: $main-gray;
        }
        &.wider {
          width: 535px;
          margin-left: 63px;
        }
        .field-title {
          font-size: 15px;
          font-weight: 400;
          color: $main-dark;
        }
        .field-value {
          font-size: 15px;
          font-weight: 700;
          color: $main-dark;
        }
      }
    }

    .invoice-table {
      .table-header {
        width: 100%;
        padding: 0 30px;
        margin-bottom: 3px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .table-header-item {
          width: 20%;
          font-size: 11px;
          font-weight: 700;
          text-transform: uppercase;
          text-align: right;
          color: #acacad;
          &:first-child {
            text-align: left;
          }
        }
      }
      .table-items {
        .single-item {
          width: 100%;
          height: 50px;
          padding: 0 30px;
          background-color: $main-gray;
          border-radius: 50px;

          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            width: 20%;
            text-align: right;
            font-size: 15px;
            font-weight: 500;
            color: $main-dark;
            &:first-child {
              text-align: left;
            }
          }
        }
      }
    }
    .tax-summary {
      .tax-header {
        width: 100%;
        padding: 0 30px;
        margin-top: 20px;
        margin-bottom: 3px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .tax-header-item {
          width: 25%;
          font-size: 11px;
          font-weight: 700;
          text-transform: uppercase;
          text-align: right;
          color: #acacad;
          &:first-child {
            text-align: left;
          }
        }
      }
      .tax-value {
        width: 100%;
        height: 50px;
        padding: 0 30px;
        background-color: $main-gray;
        border-radius: 50px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
          width: 25%;
          text-align: right;
          font-size: 15px;
          font-weight: 500;
          color: $main-dark;
          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }

  .side-img {
    width: 265px;
    height: 265px;
    padding-top: 100px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
.view-invoice-wrapper{
  .wrappTextCreate{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width:100%;
    min-height:30vh;
  }
  .import-button.createCompany{
    display: flex !important;
    justify-content: center;
    button{
      margin-top: 20px;
    }
             
  }
}

@media only screen and (max-width: 1180px) {
  .view-invoice-wrapper {
    .everything-wrapper-cuz-i-forgor-smt {
      width: 100%;
      .invoice-header {
        flex-direction: column;
        padding-left: 0;
        .left-side {
          flex-direction: column;
        }
        .right-side {
          flex-direction: column;

          .pdf {
            margin-top: 14px;
            margin-bottom: 8px;
          }
        }
      }

      .details-wrap {
        max-height: unset;
        display: flex;
        justify-content: space-between;

        .field {
          width: 100%;
          padding: 0 23px;
          &.wider {
            width: 100%;
            margin-left: 0;
          }
        }
      }

      .invoice-table {
        .table-header {
          align-items: center;
          justify-content: space-evenly;
          padding: 0 23px;
        }
        .table-items {
          .single-item {
            padding: 0 23px;
          }
        }
      }
      .tax-summary {
        .tax-header {
          align-items: center;
        }
      }
    }
  }
}
</style>
