<template>
  <div class="input-wrap" :class="{ altMargin: props.tooltip }">
    <label class="input-label" for="input">
      {{ props.label }} 
      <span v-if="info" :title="info" class="info" >?</span>
    </label>
    <input
      v-if="type !== '' "
      class="custom-input"
      name="input"
      :placeholder="`${props.placeholder || props.label}`"
      :type="type"
      :disabled="props.disabled"
      :value="modelValue"
      @input="emitChange($event)"
      @blur="closeOptions"
      @focus="showOptions"
      autocomplete="false"
      :min="min"
    />
    <input
      v-else
      class="custom-input"
      name="input"
      :placeholder="`${props.placeholder || props.label}`"
      :type="props.password && !showPassword ? 'password' : 'text'"
      :disabled="props.disabled"
      :value="modelValue"
      @input="emitChange($event)"
      @blur="closeOptions"
      @focus="showOptions"
      autocomplete="false"
    />
    <!-- @input="updateValue" -->
    <span
      v-if="props.password"
      class="material-symbols-rounded"
      :class="{ visible: showPassword }"
      @click="toggleVisibility"
    >
      visibility
    </span>
    <span
      v-if="props.dropdown"
      class="material-symbols-rounded"
      @click="toggleOptions"
    >
      expand_more
    </span>
    <span v-if="props.tooltip" class="tooltip">
      {{ props.tooltip }}
    </span>
    <div
      v-if="props.dropdown && props.options && isShowingOptions"
      class="options"
    ></div>
    <label
      v-if="props.notification"
      :class="{ shakeWarn: isShaking }"
      :style="{ color: props.notifColor }"
      class="notif-label"
    >
      {{ props.notification }}
    </label>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";

let showPassword = ref(false);
let isShowingOptions = ref(false);
let isShaking = ref(false);

const emit = defineEmits(["input", "update:modelValue"]);

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
  },
  password: {
    type: Boolean,
    required: false,
  },
  type: {
    type: String,
    required: false,
    default: "",
  },
  value: {
    type: [String, Number],
    required: false,
    default: "",
  },
  modelValue: {
    required: false,
    default: "",
  },
  tooltip: {
    type: String,
    required: false,
  },
  dropdown: {
    type: Boolean,
    required: false,
  },
  options: {
    type: Array,
    required: false,
  },
  notification: {
    type: String,
    required: false,
    default: null,
  },
  notifColor: {
    type: String,
    required: false,
    default: "red",
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  regexFilter: {
    type: RegExp,
    required: false,
  },
  min: {
    type: Number,
    required: false,
  },
  info:{
    type: String,
    required: false,
  },
});
const computedValue = computed({
  get: () => props.modelValue,
  set: (newValue) => {
    emit("input", newValue);
  },
});
function emitChange(e) {
  emit("update:modelValue", e.target.value);
}

function updateValue(evt) {
  computedValue.value = evt.target.value;
}
const showPasswordHandler = () => {
  return props.password ? "password" : "text";
};
const toggleVisibility = () => {
  showPassword.value = !showPassword.value;
};

const toggleOptions = () => {
  isShowingOptions.value = !isShowingOptions.value;
};

const showOptions = () => {
  isShowingOptions.value = true;
};

const closeOptions = () => {
  isShowingOptions.value = false;
};

const handleShake = () => {
  isShaking.value = true;
  setTimeout(() => {
    isShaking.value = false;
  }, 1500);
};
watch(
  () => props.modelValue,
  (newValue) => {
    computedValue.value = newValue;
  }
);
watch(
  () => props.notification,
  () => {
    handleShake();
  }
);
</script>

<style scoped lang="scss">
.input-wrap {
  width: 100%;
  max-width: 546px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  position: relative;
  .input-label {
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: #98a0b6;
    position: relative;
    padding-right: 20px;
    .info{
      position: absolute;
      bottom: 0;
      right: 0;
      display: inline-block;
      width: 18px;
      height: 18px;
      text-align: center;
      border: 1px solid $main-green;
      border-radius: 50%;
      color: $main-green;
      font-weight:bolder;
    }
  }
  .notif-label {
    text-align: right;
    position: absolute;
    right: 10px;
    top: 62px;
    font-size: 13px;
    font-style: italic;
  }
  .custom-input {
    width: 100%;
    height: $max-input-height;
    border: 1px solid $input-border-idle;
    border-radius: 30px;
    padding: 0 30px;
    font-weight: 600;
    font-size: 16px;
    color: $main-dark;
    line-height: 19px;
    font-family: "Archivo", sans-serif;

    &:focus {
      outline: 2px solid $main-green;
    }
    &:disabled{
      background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
    }

    &::placeholder {
      color: #acacad;
      font-weight: 400;
      font-size: $placeholder-size;
      line-height: 14px;
    }
  }
  .material-symbols-rounded {
    position: absolute;
    right: 28px;
    top: 50%;
    // top: calc(50% - 10px); // 24 / 2 = 12 
    color: #dfdfe8;
    cursor: pointer;
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  }

  .tooltip {
    align-self: flex-end;
    font-style: italic;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $main-dark;
  }
  .options {
    width: 502px;
    height: 100px;
    background-color: red;
    position: absolute;
    left: 22px;
    top: 78px;
  }
  .visible {
    color: $main-green;
    // top: 50%;
  }
}

.altMargin {
  margin-bottom: 1px;
}

@media only screen and (max-width: 800px) {
  .input-wrap {
    width: 100%;
    margin: 0;

    .custom-input {
      height: 40px;
    }
  }
}
</style>
