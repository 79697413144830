<template>
  <div class="wrapapiModal">
    <div class="wrappInput">
        <div class="wrapFilter">
            <BaseSelect
                :options="options.transactionTypes"
                :placeholder="$t('app.invoice-overview.transType')"
                :value ="selectedTransactionTypeName"
                @selected="setSelectedTransactionType"
            />
            <BaseSelect
                :options="options.invoiceTypes"
                :placeholder="$t('app.invoice-overview.invType')"
                :value ="selectedInvoiceTypeName"
                @selected="setSelectedInvoiceType"
            />
        
            <DateInput 
                :placeholder="$t('app.invoice-overview.dateFrom')"
                :max="to"
                @input="(val) => (from = val)"
                style="min-width:180px;"
            />
            <DateInput 
                style="min-width:180px;"
                @input="(val) => (to = val)"
                :min="from" 
                :placeholder="$t('app.invoice-overview.dateTo')" 
            />
        </div> 

        <div @click="confirm" class="btn keyButton">
            {{$t('app.main.generate')}}
        </div>

    </div> 
  </div>
</template>

<script setup>
import { useClientsStore } from "@/store/clientsStore";
import { ModalStore } from "@/store/modalStore";
import { TRANSACTION_TYPE, INVOICE_TYPES } from "@/assets/js/types";
import api from "@/api"

const clientsStore = useClientsStore();
const modalStore = ModalStore();

const props = defineProps(["options"]);

// params 
const from = ref('');
const to = ref('');

const selectedTransactionTypeName = ref('');
const selectedTransactionType = ref('');
const selectedInvoiceTypeName = ref('');
const selectedInvoiceType = ref('');

const setSelectedTransactionType = (type) => {
  // console.log(type);
  selectedTransactionTypeName.value = type
  selectedTransactionType.value = TRANSACTION_TYPE[type];
  // console.log(selectedTransactionType.value);
};
const setSelectedInvoiceType = (type) => {
  selectedInvoiceTypeName.value = type
  selectedInvoiceType.value = INVOICE_TYPES[type];
  // console.log(selectedInvoiceType.value);
};

async function confirm(){
    props.options.update({
        selectedTransactionType: selectedTransactionType.value,
        selectedInvoiceType: selectedInvoiceType.value,
        from: from.value,
        to: to.value,
    });
    modalStore.setModalData(null);
}
</script>

<style lang="scss" scoped>
.wrapapiModal{
    box-sizing: border-box;
     width: 100%;
     height: 100%;
}
.wrappInput{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .label{
        min-width: 150px;
        width: 100%;
        word-wrap: wrap;
        // white-space: nowrap;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 20px;
        /* identical to box height */


        color: #98A0B6;

    }
    .keyButton{
        width: 80%;
        margin-bottom: 20%;
    }
}

.wrapFilter{
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
    justify-content: space-between;
    align-items: center;
div{
    width: 85%;
    margin: 15px 15px 0 15px;
    min-width: 180px;

  }
}


</style>