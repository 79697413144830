<template>
  <div class="header-wrap">
    <div class="logo">e<span @click="goHome">Fiskalko</span></div>
    <button v-if="!isBurger" class="btn header-logout" @click="logout">
      {{ $t("app.header.headerBtnLogout") }}
    </button>
    <div v-if="isBurger" class="burgir" @click="openBurgir">
      <svg
        width="22"
        height="16"
        viewBox="0 0 22 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1C0 0.44771 0.44772 0 1 0H21C21.5523 0 22 0.44771 22 1C22 1.55229 21.5523 2 21 2H1C0.44772 2 0 1.55229 0 1Z"
          fill="#344368"
        />
        <path
          d="M0 7.99988C0 7.44758 0.44772 6.99988 1 6.99988H21C21.5523 6.99988 22 7.44758 22 7.99988C22 8.55218 21.5523 8.99988 21 8.99988H1C0.44772 8.99988 0 8.55218 0 7.99988Z"
          fill="#344368"
        />
        <path
          d="M1 13.9998C0.44772 13.9998 0 14.4475 0 14.9998C0 15.5521 0.44772 15.9998 1 15.9998H21C21.5523 15.9998 22 15.5521 22 14.9998C22 14.4475 21.5523 13.9998 21 13.9998H1Z"
          fill="#344368"
        />
      </svg>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";
import { logout } from "@/assets/js/helpers";
const isBurger = inject("isBurger");

const router = useRouter();

const emit = defineEmits(["openBurgir"]);

const openBurgir = () => {
  emit("openBurgir");
};

const goHome = () => {
  router.push("/");
};
</script>

<style lang="scss" scoped>
.header-wrap {
  width: 100%;
  height: 80px;
  // position: absolute;
  // left: 0;
  // top: 0;
  background-color: #fff;
  padding: 0 90px 0 100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);

  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    cursor: pointer;
  }
  .header-logout {
    width: 150px;
    height: 40px;
    background-color: $main-green;
  }
  .burgir {
    height: 30%;
    min-height: 25px;
    svg {
      fill: $main-dark;
      width: 100%;
      height: 100%;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .header-wrap {
    height: $header-height-mobile;
    padding: 13px 25px;
  }
}

@media only screen and (max-width: 800px) {
  .header-wrap {
    height: $header-height-mobile;
    padding: 13px 25px;
  }
}
</style>
