<template>
  <div class="component-wrap">
    <div class="content-header">
      <div class="title">
        <h2>
          {{ $t("app.profile.title") }}
        </h2>
      </div>
    </div>
    <div class="bigger-wrap">
      <div class="big-wrap">
        <div v-if="!isEditing" class="profile-info-wrap">
          <div
            class="profile-field"
            v-for="(value, key, index) in profileInfo"
            :key="key"
            :class="{ alt: index % 2 === 0 }"
          >
            <div class="field-label">{{ labelsForProfile[index] }}</div>
            <div class="field-value">{{ value }}</div>
          </div>
        </div>
        <button v-if="!isEditing" class="btn change" @click="toggleChange">
          {{ $t("app.profile.changeBtn") }}
        </button>
        <div v-else class="edit-profile-info-wrap">
          <BaseInput
            :label="$t(`app.profile.nameInputLabel`)"
            v-model="profileInfo.usr_name"
            :notification="nameNotif"
            
          /><!-- @input="onInputProfileName" -->
          <BaseInput
            :label="$t(`app.profile.lastnameInputLabel`)"
            v-model="profileInfo.usr_last_name"
            :notification="lastNameNotif"
            
          /><!-- @input="onInputProfileLastName" -->
          <BaseInput
            :label="$t(`app.profile.emailInputLabel`)"
            v-model="profileInfo.usr_email"
            disabled
          /> <!-- @input="onInputProfileEmail" -->

          <BaseInput
            :label="$t(`app.profile.oldPasswordInputLabel`)"
            v-model="profile_old_password"
            :notification="oldPassNotif === '' ? '' : $t(oldPassNotif)"
            password
          />
          <!-- @input="onInputProfileOldPassword" -->
          <BaseInput
            :label="$t(`app.profile.newPasswordInputLabel`)"
            v-model="profile_new_password"
            :notification="newPassNotif === '' ? '' : $t(newPassNotif)"
            password
          />
          <!-- @input="onInputProfileNewPassword" -->
        </div>
         <div :class="{ shakeWarn: isErrorNotifShaking }" class="errmsg">
            {{ errorNotif === "" ? "" : $t(errorNotif) }}
          </div>
        <div v-if="isEditing" class="wrappBtn">
          <button v-if="isEditing" class="btn save reset" @click="resetChanges">
            {{ $t("app.profile.return") }}
          </button>
          <button v-if="isEditing" class="btn save" @click="saveChanges">
            {{ $t("app.profile.saveBtn") }}
          </button>
        
        </div>
        
      </div>
      <div class="side-img">
        <img
          v-if="!isEditing"
          src="../../assets/images/profile-img.png"
          alt=""
        />
        <img v-else src="../../assets/images/edit-profile.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import api from "../../api/index.js";
import { useClientsStore } from "../../store/clientsStore";

const clientStore = useClientsStore();
let isEditing = ref(false);
let profileInfo = ref(null);
let labelsForProfile = ref(["User Name", "Last Name", "User Email", "User ID"]);

let nameNotif = ref("");
let lastNameNotif = ref("");
let oldPassNotif = ref("");
let newPassNotif = ref("");
let errorNotif = ref("");
let isErrorNotifShaking = ref(false);


const toggleChange = () => {
  isEditing.value = !isEditing.value;
};

async function getProfileInfo() {
  try {
   
    let res = await api.getProfile();
    profileInfo.value = res.data.data;
    profile_name.value = profileInfo.value.usr_name;
    profile_last_name.value = profileInfo.value.usr_last_name;
    profile_email.value = profileInfo.value.usr_email;
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  getProfileInfo();
});

let profile_name = ref("");
let profile_last_name = ref("");
let profile_email = ref("");
let profile_old_password = ref("");
let profile_new_password = ref("");

// const onInputProfileName = (value) => (profile_name.value = value);
// const onInputProfileLastName = (value) => (profile_last_name.value = value);
// const onInputProfileEmail = (value) => (profile_email.value = value);
// const onInputProfileOldPassword = (value) =>
//   (profile_old_password.value = value);
// const onInputProfileNewPassword = (value) =>
//   (profile_new_password.value = value);

const saveChanges = async () => {
  try {
   /* const params = {
      usr_name: profile_name.value,
      usr_last_name: profile_last_name.value,
      usr_password: profile_new_password.value,
      old_password: profile_old_password.value,
    };
    */
   let params = checkChangePramas()
  //  console.log(params)
  //  console.log(typeof params)
   if (!params) {
    return
   } else if ( Object.keys(params).length === 0){
    errorNotif.value = "app.profile.nothingChange"
    isErrorNotifShaking.value = true
    return
   }

    let res = await api.editProfile(params);
    isEditing.value = false;
    resetChanges()
  } catch (error) {
    console.log(error.response);
    console.log(error.response.request.status)
    console.log(error.response.data.message)
    if(error.response.request.status === 400 && error.response.data.message ==="invalid-usr_password") {
      newPassNotif.value = 'app.profile.passNotValid'
    }
     if(error.response.request.status === 400 && error.response.data.message ==="invalid-password") {
      oldPassNotif.value = 'app.profile.passNotValid'
    }
  }
};
function resetChanges (){
   isEditing.value = false;
   getProfileInfo();
   resetErr();
   profile_old_password.value = '';
   profile_new_password.value = '';

}

function checkChangePramas () {
  resetErr()
  let params = {}
      // name
    if(profile_name.value !== profileInfo.value.usr_name 
       && profileInfo.value.usr_name.length > 0){
        params.usr_name = profileInfo.value.usr_name
    } 
    // last name
    if(profile_last_name.value !== profileInfo.value.usr_last_name  
      && profileInfo.value.usr_last_name.length > 0){
       params.usr_last_name = profileInfo.value.usr_last_name
    }
    // password
    if(profile_new_password.value.length > 0 
      &&  profile_old_password.value.length > 0){

          // new password check
          if (!/[A-Z]/.test(profile_new_password.value)) {
            newPassNotif.value = "app.auth.registerPage.form.passwordUpperCaseMissing"
            return false
          }
          if (!/[a-z]/.test(profile_new_password.value)) {
            newPassNotif.value ="app.auth.registerPage.form.passwordLowerCaseMissing"
            return false
          }
          if (!/[0-9]/.test(profile_new_password.value)) {
            newPassNotif.value = "app.auth.registerPage.form.passwordNumberCaseMissing"
            return false
          }
          if (profile_new_password.value.length > 32 
          || profile_new_password.value.length < 8) {
            newPassNotif.value = "app.auth.registerPage.form.passwordLength"
            return false
          }

        params.usr_password = profile_new_password.value
        params.old_password = profile_old_password.value
    } else if(
      profile_new_password.value.length > 0
       &&  profile_old_password.value.length === 0
    ) {
      // error
      oldPassNotif.value = 'app.profile.oldPassRequired'
      return false
    } else if(
      profile_new_password.value.length === 0
       &&  profile_old_password.value.length > 0
    ) {
      // error
      newPassNotif.value = 'app.profile.newPassRequired'
      return false
    }
 return params

}
function resetErr(){
  nameNotif.value = ''
  lastNameNotif.value = ''
  oldPassNotif.value = ''
  newPassNotif.value = ''
  errorNotif.value = ''
  isErrorNotifShaking.value = false
}
</script>

<style lang="scss" scoped>
.component-wrap {
  .bigger-wrap {
    display: flex;
    .big-wrap {
      width:50%;
      .profile-info-wrap {
        margin: 30px 0;
        width:100%;
        .profile-field {
          // width: 646px;
          width:100%;
          height: 56px;
          background-color: $main-gray;
          border-radius: 30px;
          padding: 0 50px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .field-label {
            width: 40%;
            box-sizing: border-box;
            padding-right: 10px;
            text-align: left;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $main-dark;
          }

          .field-value {
            width: 60%;
            text-align: left;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $main-dark;
            overflow: hidden;
            align-items: flex-start;
            text-overflow: ellipsis;
          }
        }
        .alt {
          background-color: #fff;
        }
      }
      .change {
        width: 150px;
        height: 38px;
      }


      .edit-profile-input-wrap {
        margin: 30px 0;
      }

      .save {
        width: fit-content;
        min-width:150px;
        height: 38px;
        margin-bottom: 20px;
      }
      .reset{
        background: #fff;
        color: $main-green;
        border: 1px solid $main-green;
      }
    }

    .side-img {
      margin: auto 0;
      min-width: 330px;
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80%;
      }
    }
  }
}
.wrappBtn{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
// @media only screen and (max-width: 1700px) {
//   .side-img {
//       display: none !important;
//     }
// }
.errmsg {
  height: 30px;
  margin-top: 0px;
  color: red;
  text-align: center;
}
@media only screen and (max-width: 1050px) {
  .component-wrap {
    .bigger-wrap {
      flex-direction: column;
      .big-wrap {
        width: 100%;
        .profile-info-wrap {
          width: 100%;
          .profile-field {
            width: 100%;
            padding: 0 20px;
            .field-label {
              font-size: 14px;
              line-height: 15px;
            }

            .field-value {
              font-size: 14px;
              line-height: 15px;
            }
          }
        }
        .change {
          width: 100%;
        }
        .edit-profile-info-wrap {
          width: 100%;

          > .input-wrap {
            margin: auto;
            margin-bottom: 20px;

          }
        }
        // .save {
        //   width: 100%;
        // }
      }
      .side-img {
        min-width: 100%;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  .wrappBtn{
    margin: auto;
    width: 90%;
  }
}
</style>
