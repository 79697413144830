import { defineStore } from "pinia";
// import api from "@/api";
export const useArticleStore = defineStore('Article', () => {

    let article = ref({
        art_id: "",
        art_name: "",
        art_gtin: "",
        art_base_price: "",
        art_base_labels: [],
    })

    function setArticleData(data) {
        for (const property in article.value) {
            console.log(`${property}: ${data[property]}`);
            if (Object.hasOwnProperty.call(data, property)) {
                article.value[property] = data[property]
            } else {
                if (property === 'art_base_labels') {
                    article.value[property] = []
                } else {
                    article.value[property] = ""
                }
            }

        }

    }

    // const getArticleData = computed(() => {
    //     return article.value;
    // });
    function getArticleData() {
        return article.value;
    };



    return {
        article,
        setArticleData,
        getArticleData,
    };
});